import { Flex } from '@chakra-ui/react'
import { IoMdBusiness } from 'react-icons/io'
import { Order_By, useGetOrganizationsQuery } from '../../generated/graphql'
import { useOrgID } from '../../hooks/id_token_claims'
import { genReportFilter } from '../../hooks/report_table_filters'
import { ReportFilterType, OrganizationStatus } from '../../utils/constants'
import { ReportFilterDropdown, SHOW_ALL_FILTER_VALUE } from '../tables/filter_dropdown'
import { IoCubeSharp } from 'react-icons/io5'
import { OrganizationStatusLabels } from '@/generated/enums'

export const ALLOWED_ORG_STATUSES_FOR_UNIFIED_REPORTS = [
  OrganizationStatus.ACTIVE,
  OrganizationStatus.EVALUATING,
  OrganizationStatus.PITCHING,
  OrganizationStatus.TRIAL_ENDED,
]

const getOrgDisplayValue = (value, allOrgsById) => {
  if (value === ReportFilterType.Organization) {
    return 'Org'
  } else {
    return value === SHOW_ALL_FILTER_VALUE
      ? SHOW_ALL_FILTER_VALUE
      : allOrgsById[value].name
  }
}

const getOrgStatusDisplayValue = (value) => {
  if (value === ReportFilterType.OrgStatus) {
    return 'Org Status'
  } else {
    return value === SHOW_ALL_FILTER_VALUE
      ? SHOW_ALL_FILTER_VALUE
      : OrganizationStatusLabels[value]
  }
}

const UnifiedReportsTableFilters = () => {
  const orgId = useOrgID()

  const { data: allOrgsData, loading: allOrgsLoading } = useGetOrganizationsQuery({
    variables: {
      organizationsWhere: {
        organization_status: {
          _in: ALLOWED_ORG_STATUSES_FOR_UNIFIED_REPORTS,
        },
      },
      orderBy: [{ name: Order_By.Asc }],
    },
  })

  if (allOrgsLoading) {
    return <></>
  }

  const allOrgsById = allOrgsData.organizations.reduce((acc, org) => {
    acc[org.id] = org
    return acc
  }, {})

  const genReportFilters = (reportFilterType: ReportFilterType, values: string[]) => {
    return values.map((value) => genReportFilter(reportFilterType, value))
  }

  const organizationStatusFilterOptions = genReportFilters(
    ReportFilterType.OrgStatus,
    ALLOWED_ORG_STATUSES_FOR_UNIFIED_REPORTS,
  )

  const organizationFilterOptions = genReportFilters(
    ReportFilterType.Organization,
    allOrgsData.organizations.map((org) => org.id),
  )
  if (!allOrgsData.organizations.length) {
    return <></>
  }

  return (
    <Flex align="left" flexDirection="row">
      <ReportFilterDropdown
        dropdownIcon={<IoCubeSharp size="18" />}
        filterOptions={organizationStatusFilterOptions}
        filterType={ReportFilterType.OrgStatus}
        isInternal={true}
        isMultiSelect={true}
        marginRight="8px"
        valueToDisplayFunction={(value) => getOrgStatusDisplayValue(value)}
      />

      <ReportFilterDropdown
        dropdownIcon={<IoMdBusiness size="18" />}
        filterOptions={organizationFilterOptions}
        filterType={ReportFilterType.Organization}
        isInternal={true}
        isMultiSelect={true}
        marginRight="8px"
        showSearchBar={true}
        valueToDisplayFunction={(orgId) => getOrgDisplayValue(orgId, allOrgsById)}
      />
    </Flex>
  )
}

export default UnifiedReportsTableFilters
