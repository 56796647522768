import { IdToken, useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/nextjs'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import {
  useGetLastOrgContextLazyQuery,
  useOrganizationLazyQuery,
} from '@/generated/graphql'
import { usePostHog } from 'posthog-js/react'

const IDTokenClaimsContext = createContext(null)

export function useOrgID() {
  const { organizationData } = useContext(IDTokenClaimsContext)
  const [orgData, _setOrgData] = organizationData

  return orgData?.id
}

export function getOrgData() {
  const { authorizedOrgs, organizationData } = useContext(IDTokenClaimsContext)
  const [orgData, setOrgData] = organizationData
  const [authorizedOrgIds, _setAuthorizedOrgIds] = authorizedOrgs

  const [userID] = useUserID()

  const [
    useGetLastOrgContext,
    { data: lastOrgData, loading: lastOrgLoading, error: lastOrgError },
  ] = useGetLastOrgContextLazyQuery({
    variables: {
      user_id: userID,
    },
  })

  const [useGetOrganization, { data: fallbackOrgData, error: fallbackOrgError }] =
    useOrganizationLazyQuery({
      variables: {
        organization_id: authorizedOrgIds?.[0],
      },
    })

  useEffect(() => {
    if (userID) {
      useGetLastOrgContext()
    }
  }, [userID])

  useEffect(() => {
    if (authorizedOrgIds && authorizedOrgIds.length > 0) {
      useGetOrganization()
    }
  }, [authorizedOrgIds])

  useEffect(() => {
    if (userID && lastOrgError) {
      console.error('Error fetching last organization context:', lastOrgError)
      Sentry.captureException(lastOrgError)
    }
    if (authorizedOrgIds && fallbackOrgError) {
      console.error('Error fetching fallback organization data:', fallbackOrgError)
      Sentry.captureException(fallbackOrgError)
    }
  }, [lastOrgError, fallbackOrgError])

  useEffect(() => {
    if (orgData) {
      return
    }

    // this effect will set the orgData for the first time based off of lastContext and fallback to authorizedOrgs
    if (lastOrgData?.users_by_pk?.last_org && !orgData) {
      setOrgData(lastOrgData?.users_by_pk?.last_org)
    } else if (!lastOrgLoading && fallbackOrgData?.organizations_by_pk) {
      setOrgData(fallbackOrgData?.organizations_by_pk)
    } else if (userID && !lastOrgLoading) {
      console.error('Error setting orgData')
      Sentry.configureScope((scope) => {
        scope.setExtra('userID', userID)
        scope.setExtra('lastOrgData', lastOrgData)
        scope.setExtra('fallbackOrgData', fallbackOrgData)

        Sentry.captureException('Error setting orgData')
      })
    }
  }, [lastOrgData?.users_by_pk?.last_org, lastOrgLoading, fallbackOrgData])

  return [orgData, setOrgData]
}

export function useUserID() {
  const { user } = useContext(IDTokenClaimsContext)
  const [userID, setUserID] = user
  return [userID, setUserID]
}

export function useIsEmployeeView() {
  const { employeeView } = useContext(IDTokenClaimsContext)
  const [isEmployeeView, setIsEmployeeView] = employeeView
  return [isEmployeeView, setIsEmployeeView]
}

export function useShouldApplyCustomerPermissions() {
  const { applyCustomerPermissions } = useContext(IDTokenClaimsContext)
  const [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions] =
    applyCustomerPermissions
  return [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions]
}

export function useIsEmployee() {
  const { employee } = useContext(IDTokenClaimsContext)
  const [isEmployee] = employee
  return isEmployee
}

export function useAuthorizedOrgIds() {
  const { authorizedOrgs } = useContext(IDTokenClaimsContext)
  const [authorizedOrgIds, setAuthorizedOrgIds] = authorizedOrgs
  return [authorizedOrgIds, setAuthorizedOrgIds]
}

export function useEmail() {
  const { emailAddress } = useContext(IDTokenClaimsContext)
  const [email, setEmail] = emailAddress
  return [email, setEmail]
}

export const IDTokenClaimsProvider: FC<{ children }> = ({ children }) => {
  const [orgData, setOrgData] = useState()
  const [userID, setUserID] = useState()
  const [isEmployeeView, setIsEmployeeView] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)
  const [authorizedOrgIds, setAuthorizedOrgIds] = useState([])
  const [email, setEmail] = useState(null)
  const [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions] =
    useState(false)

  const { getIdTokenClaims, isAuthenticated } = useAuth0()
  const posthog = usePostHog()
  useEffect(() => {
    getIdTokenClaims().then((idTokenClaims: IdToken) => {
      if (!idTokenClaims?.hasOwnProperty('https://hasura.io/jwt/claims')) {
        return
      }

      const computedUserID =
        idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-user-id']
      setUserID(computedUserID)
      const computedEmail = idTokenClaims['email']
      setEmail(computedEmail)
      const availableOrgIds =
        idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-org-ids']?.match(
          /[\w.-]+/g,
        )
      setAuthorizedOrgIds(availableOrgIds || [])
      if (!availableOrgIds) {
        Sentry.captureMessage('Available org ids is empty', {
          extra: {
            authorizedOrgIds:
              idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-org-ids'],
            newFlag:
              idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-organization-id'],
          },
        })
      }
      const computedIsEmployee =
        idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-is-employee'] == '1'
      setIsEmployeeView(computedIsEmployee)
      setIsEmployee(computedIsEmployee)

      Sentry.setContext('app_context', {
        userID: computedUserID,
        isEmployee: computedIsEmployee,
        email: computedEmail,
        availableOrgIds: availableOrgIds,
      })

      posthog.identify(computedUserID, {
        email: computedEmail,
        availableOrgIds: availableOrgIds,
        isEmployee: computedIsEmployee,
      })
    })
  }, [isAuthenticated, getIdTokenClaims])

  return (
    <IDTokenClaimsContext.Provider
      value={{
        user: [userID, setUserID],
        employeeView: [isEmployeeView, setIsEmployeeView],
        employee: [isEmployee],
        organizationData: [orgData, setOrgData],
        authorizedOrgs: [authorizedOrgIds, setAuthorizedOrgIds],
        emailAddress: [email, setEmail],
        applyCustomerPermissions: [
          shouldApplyCustomerPermissions,
          setShouldApplyCustomerPermissions,
        ],
      }}
    >
      {children}
    </IDTokenClaimsContext.Provider>
  )
}
