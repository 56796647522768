import { HStack, Icon, Tooltip, VStack, Text, Box, Heading } from '@chakra-ui/react'
import { IoInformationCircleOutline } from 'react-icons/io5'
import {
  DOPPEL_DARK_SECONDARY,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_TEXT_GREY,
  DOPPEL_TEXT_WHITE,
} from '@/utils/style'
import posthog from 'posthog-js'
import { useIsEmployee } from '@/hooks/id_token_claims'

const FeatureFlagDisplay: React.FC = () => {
  const featureFlags = posthog.featureFlags.getFlagVariants()

  const isEmployee = useIsEmployee()

  if (!isEmployee) {
    return <></>
  }

  return (
    <Tooltip
      bg={DOPPEL_DARK_SECONDARY}
      borderRadius={4}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.2)'}
      color={DOPPEL_TEXT_WHITE}
      label={
        <Box px={1.5} py={3}>
          <Heading size="sm">PostHog Feature Flags</Heading>

          <VStack align="flex-start" mt={2} spacing={1} width="100%">
            {Object.entries(featureFlags).map(([flag, value]) => (
              <HStack key={flag} spacing={1}>
                <Text>{flag}: </Text>

                {value ? (
                  <Text fontWeight="bold">Enabled</Text>
                ) : (
                  <Text color={DOPPEL_TEXT_GREY}>Disabled</Text>
                )}
              </HStack>
            ))}
          </VStack>
        </Box>
      }
      placement="bottom-start"
    >
      <Box>
        <Icon as={IoInformationCircleOutline} color={DOPPEL_INTERNAL_PURPLE} />
      </Box>
    </Tooltip>
  )
}

export default FeatureFlagDisplay
