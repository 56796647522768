export const DOPPEL_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/Doppel_Avatar_Square.png'
export const DOPPEL_CHECKMARK =
  'https://storage.googleapis.com/doppel_vision_assets/doppel_checkmark_2.png'
export const DOPPEL_ALERT_ICON =
  'https://storage.googleapis.com/doppel_vision_assets/doppel_alert_icon.png'
export const DOPPEL_BOUNDING_CIRCLE =
  'https://storage.googleapis.com/doppel_vision_assets/bounding_circle.png'
export const DOPPEL_EDIT_ICON =
  'https://storage.googleapis.com/doppel_vision_assets/edit_icon2.png'
export const DOPPEL_KEYWORD_MATCH =
  'https://storage.googleapis.com/doppel_vision_assets/keyword_match_img.png'

// Entity Statuses
export const DOPPEL_DNS_PARKED =
  'https://storage.googleapis.com/doppel_vision_assets/dns_parked.png'
export const DOPPEL_EMPTY_WEBSITE =
  'https://storage.googleapis.com/doppel_vision_assets/empty_website.png'
export const DOPPEL_SCRAPING_IN_PROGRESS =
  'https://storage.googleapis.com/doppel_vision_assets/scraping_in_progress.png'
export const DOPPEL_SCRAPING_BLOCKED =
  'https://storage.googleapis.com/doppel_vision_assets/scraping_blocked.png'

// Crypto Logos
export const ETH_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/eth_logo.png'
export const SOL_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/solana_logo.png'
export const MATIC_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/polygon_logo.png'
export const FLOW_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/flow_icon.png'

// Crypto Marketplaces
export const OPENSEA_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/opensea_logo.webp'
export const RARIBLE_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/rarible_logo.png'
export const MAGICEDEN_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/magiceden_logo.webp'
export const X2Y2_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/x2y2_logo.jpeg'
export const LOOKSRARE_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/looksrare_logo.jpeg'
export const JUNGLE_LOGO_URL =
  'https://storage.googleapis.com/doppel_vision_assets/jungle_logo.jpeg'

export const CHAIN_TO_LOGO_URL: { [key: string]: string } = {
  eth: ETH_LOGO_URL,
  sol: SOL_LOGO_URL,
  matic: MATIC_LOGO_URL,
  flow: FLOW_LOGO_URL,
}
