import {
  ReportSource,
  ReportSourceCategory,
  ReportSourceLabels,
  PageRoute,
  PlatformSubtype,
  ReportSourceCategoryLabels,
  ReportStatus,
} from '@/generated/enums'
import { Spoof_Matches } from '../../generated/graphql'
import { Classification, ReportType, ProductType } from '../constants'
import { validate as uuidValidate } from 'uuid'
import { NextRouter } from 'next/router'
import { computeQueryParams } from './query_params'
import { getBucketNameAndObjectName } from '@/utils/gcs_utils'

export const REPORT_TYPE_TO_PATH: { [key in ReportType]: string } = {
  [ReportType.DOMAINS]: 'domains',
  [ReportType.SOCIAL_MEDIA]: 'socialmedia',
  [ReportType.ECOMMERCE]: 'ecommerce',
  [ReportType.EMAIL]: 'email',
  [ReportType.MOBILE_APPS]: 'mobileapps',
  [ReportType.PAID_ADS]: 'paidads',
  [ReportType.CRYPTO]: 'crypto',
  [ReportType.NFTS]: 'nfts',
  [ReportType.METAVERSE]: 'gaming',
  [ReportType.DARK_WEB]: 'darkweb',
  [ReportType.DARK_MARKET]: 'darkmarket',
  [ReportType.CODE_REPOS]: 'code-repos',
  [ReportType.TELCO]: 'telco',
  [ReportType.SUSPICIOUS_EMAILS]: 'suspicious-emails',
}

export const productTypeToPath = (productType): string => {
  return productType ? productType.replace('_', '') : ''
}

export const PATH_TO_REPORT_TYPE: { [key: string]: ReportType } = Object.entries(
  REPORT_TYPE_TO_PATH,
).reduce((acc, [key, value]) => {
  acc[value] = key
  return acc
}, {} as { string: ReportType })

export const getReportTypeFromPath = (router: NextRouter): ReportType => {
  let reportTypePathSegment: string = null
  if (router.query.report_type) {
    // handles the unified reports page: /admin/reports/[report_type]
    reportTypePathSegment = router.query.report_type as string
  }
  const firstPathSegment = router.pathname.split('/')[1]
  if (firstPathSegment in PATH_TO_REPORT_TYPE) {
    // handles the main reports page: /[report_type] (which doesn't use query param)
    reportTypePathSegment = firstPathSegment
  }
  return PATH_TO_REPORT_TYPE[reportTypePathSegment] ?? null
}

export const isTableViewPath = (router: NextRouter): boolean => {
  if (router.query.report_type) return true // handles all alerts page
  const firstPathSegment = router.pathname.split('/')[1]
  const secondPathSegment = router.pathname.split('/')[2]
  const validPaths = [...Object.keys(PATH_TO_REPORT_TYPE), 'reports', 'alerts']
  return validPaths.includes(firstPathSegment) && !secondPathSegment
}

export const PLATFORM_TYPE_DISPLAY_MAP: { [key in ReportType]?: ProductType } = {
  [ReportType.DARK_MARKET]: ProductType.DARK_MARKET,
  [ReportType.DARK_WEB]: ProductType.DARK_WEB,
  [ReportType.METAVERSE]: ProductType.METAVERSE,
  [ReportType.DOMAINS]: ProductType.DOMAIN,
  [ReportType.SOCIAL_MEDIA]: ProductType.SOCIAL_MEDIA,
  [ReportType.ECOMMERCE]: ProductType.ECOMMERCE,
  [ReportType.EMAIL]: ProductType.EMAIL,
  [ReportType.MOBILE_APPS]: ProductType.MOBILE_APPS,
  [ReportType.PAID_ADS]: ProductType.PAID_ADS,
  [ReportType.CRYPTO]: ProductType.CRYPTO,
  [ReportType.NFTS]: ProductType.NFT,
  [ReportType.CODE_REPOS]: ProductType.CODE_REPOS,
  [ReportType.TELCO]: ProductType.TELCO,
  [ReportType.SUSPICIOUS_EMAILS]: ProductType.SUSPICIOUS_EMAILS,
}

export const PRODUCT_TYPE_TO_REPORT_TYPE_MAP: { [key in ProductType]?: ReportType } = {
  [ProductType.DARK_MARKET]: ReportType.DARK_MARKET,
  [ProductType.DARK_WEB]: ReportType.DARK_WEB,
  [ProductType.METAVERSE]: ReportType.METAVERSE,
  [ProductType.DOMAIN]: ReportType.DOMAINS,
  [ProductType.SOCIAL_MEDIA]: ReportType.SOCIAL_MEDIA,
  [ProductType.ECOMMERCE]: ReportType.ECOMMERCE,
  [ProductType.EMAIL]: ReportType.EMAIL,
  [ProductType.MOBILE_APPS]: ReportType.MOBILE_APPS,
  [ProductType.PAID_ADS]: ReportType.PAID_ADS,
  [ProductType.CRYPTO]: ReportType.CRYPTO,
  [ProductType.NFT]: ReportType.NFTS,
  [ProductType.CODE_REPOS]: ReportType.CODE_REPOS,
  [ProductType.TELCO]: ReportType.TELCO,
  [ProductType.SUSPICIOUS_EMAILS]: ReportType.SUSPICIOUS_EMAILS,
}

export const REPORT_TYPES_WITH_PLATFORMS: ReportType[] = [
  ReportType.SOCIAL_MEDIA,
  ReportType.MOBILE_APPS,
  ReportType.DARK_WEB,
  ReportType.DARK_MARKET,
  ReportType.ECOMMERCE,
  ReportType.PAID_ADS,
  ReportType.CRYPTO,
]

export const METAVERSE_TYPES = ['roblox']
export const METAVERSE_PLATFORM_SUBTYPES = [PlatformSubtype.ROBLOX]

export const DARK_WEB_TYPES = ['darkweb', 'cred_leaks', 'credit_card_leaks']

export const CODE_REPOS_TYPES = ['github']
export const CODE_REPOS_PLATFORM_SUBTYPES = [PlatformSubtype.GITHUB]

export const getBestMatchFlaggedUrl = (spoofMatches: Spoof_Matches[]) => {
  if (!spoofMatches || spoofMatches.length === 0) {
    return null
  }

  const classifications = Object.values(Classification)
  const sortedSpoofMatches = [...spoofMatches].sort(
    (a, b) =>
      classifications.indexOf(a.classification) -
      classifications.indexOf(b.classification),
  )
  return sortedSpoofMatches[0]?.full_url?.url
}

export const getBestMatch = (spoofMatches: Spoof_Matches[]) => {
  if (!spoofMatches || spoofMatches.length === 0) {
    return null
  }

  const classifications = Object.values(Classification)
  const sortedSpoofMatches = [...spoofMatches].sort(
    (a, b) =>
      classifications.indexOf(a.classification) -
      classifications.indexOf(b.classification),
  )
  return sortedSpoofMatches[0]
}

export const getTypesFromReportType = (
  reportType: ReportType,
  reportPlatformTypes: string[],
): string[] => {
  // metaverse and dark web are special cases for now
  switch (reportType) {
    case ReportType.METAVERSE:
      return METAVERSE_TYPES
    case ReportType.DARK_WEB:
      return DARK_WEB_TYPES
    default:
      return reportPlatformTypes || []
  }
}
export const getDoppelLink = (
  reportId: string,
  product = 'nfts',
  orgAbbrName?: string,
  externalId?: number,
): string => {
  const path = product === 'nfts' ? 'nfts' : 'alerts'
  if (orgAbbrName && externalId) {
    return `https://app.doppel.com/${path}/${orgAbbrName}-${externalId}`
  }
  return `https://app.doppel.com/${path}/${reportId}`
}

export const EXTERNAL_QUEUE_STATES = [
  ReportStatus.NEEDS_REVIEW,
  ReportStatus.NEEDS_ACTION,
  ReportStatus.REPORTED,
  ReportStatus.RESOLVED,
  ReportStatus.NO_ACTION,
  ReportStatus.ARCHIVED,
]

export const getReportStatuses = (isEmployeeView: boolean): ReportStatus[] => {
  const reportStatuses = [...EXTERNAL_QUEUE_STATES]
  if (isEmployeeView) {
    reportStatuses.push(ReportStatus.INTERNAL_REVIEW, ReportStatus.INTERNAL_IGNORED)
  }

  return reportStatuses
}

export const getProductsByReportType = (selectedProduct: string): ProductType[] => {
  if (!selectedProduct) {
    return []
  }
  // get products for a given report type, if report type not in map then get all products
  const products: ProductType[] =
    selectedProduct in PLATFORM_TYPE_DISPLAY_MAP
      ? [PLATFORM_TYPE_DISPLAY_MAP[selectedProduct]]
      : Object.values(ProductType)

  // remove NFT from product types
  return products.filter((product) => product !== ProductType.NFT)
}

/**
 * Display a user, when internal users' identities should be hidden to customers
 *
 * @param user to be displayed to the client
 * @param isEmployeeView whether FE is in employee or customer view
 * @returns user's name, or 'Doppel' if user is internal and FE is in customer view
 */
export const getExternalUser = (
  user: any,
  isEmployeeView: boolean,
): string | undefined => {
  if (!user) return undefined
  if (!isEmployeeView && user?.is_internal) {
    return 'Doppel'
  }
  return user?.name?.split(' ')[0]
}

export const categoryToReportSources = (category: ReportSourceCategory) => {
  return Object.values(ReportSource).filter(
    (source) => (ReportSourceLabels[source] as ReportSourceCategory) === category,
  )
}

export const reportSourceToCategory = (source: ReportSource) => {
  return ReportSourceLabels[source] as ReportSourceCategory
}

export const getReportSourceDisplay = (report, isEmployeeView, orgName) => {
  const sourceCategory = reportSourceToCategory(report.source as ReportSource)
  const showCustomerSource = report.is_customer_sourced && report.uploader?.is_internal
  let displaySource = ReportSourceCategoryLabels[sourceCategory]
  if (sourceCategory === ReportSourceCategory.ANALYST_UPLOAD && report.uploader) {
    displaySource += `: ${getExternalUser(report.uploader, isEmployeeView)}`
  }
  if (showCustomerSource) {
    displaySource += ` on behalf of ${orgName}`
  }
  if (isEmployeeView) {
    displaySource += ` (${report.source})`
  }

  return displaySource
}

export function getTopReportMatch(selectedSpoofReport) {
  let sortedSpoofMatches = []
  if (selectedSpoofReport?.spoof_matches?.length) {
    const classifications = Object.values(Classification)
    sortedSpoofMatches = [...selectedSpoofReport.spoof_matches].sort(
      (a, b) =>
        classifications.indexOf(a?.classification) -
        classifications.indexOf(b?.classification),
    )
  }

  const topMatch = sortedSpoofMatches.length && sortedSpoofMatches[0]
  return topMatch
}

export function getReportFullUrl(selectedSpoofReport) {
  const topMatch = getTopReportMatch(selectedSpoofReport)
  return topMatch && topMatch.full_url ? topMatch.full_url : null
}

export function getReportSocialMediaData(selectedSpoofReport) {
  const topMatch = getTopReportMatch(selectedSpoofReport)
  return topMatch && topMatch.social_media_data ? topMatch.social_media_data : null
}

export function getReportMobileAppData(selectedSpoofReport) {
  const topMatch = getTopReportMatch(selectedSpoofReport)
  return topMatch && topMatch.mobile_app ? topMatch.mobile_app : null
}

export function verifyVersionedName(bucketName, screenshotUrl, id) {
  const regex = new RegExp(`${bucketName}/(.+)`)
  const match = screenshotUrl?.match(regex)
  const matchInfo = match ? match[1] : null

  return matchInfo == id ? id : null
}

export enum ScreenshotUrlTable {
  FULL_URLS = 'full_urls',
  SOCIAL_MEDIA_DATA = 'social_media_data',
  MOBILE_APPS = 'mobile_apps',
  SPOOFING_REPORTS = 'spoofing_reports',
}

export type VersionedScreenshotInfo = {
  name: string
  table: ScreenshotUrlTable
  existing: boolean // true if versioned screenshot already exists, indicated by screenshot_url
  bucketName?: string
  id?: string
}

export function getVersionedScreenshotInfo(
  selectedSpoofReport,
  bucketName: string,
  verifyNames: boolean,
): VersionedScreenshotInfo | null {
  // priority goes full URL -> social media data -> -> mobile app data -> report level screenshot

  const reportFullUrl = getReportFullUrl(selectedSpoofReport)
  if (reportFullUrl) {
    const fullUrlId = reportFullUrl.id
    const screenshotUrl = reportFullUrl.screenshot_url

    const versionedName = verifyVersionedName(bucketName, screenshotUrl, fullUrlId)
    if (versionedName || !verifyNames) {
      return {
        name: fullUrlId,
        table: ScreenshotUrlTable.FULL_URLS,
        existing: !!versionedName,
      }
    }
  }

  // need to decide which stored url we want for social media AND mobile app
  const reportSocialMediaData = getReportSocialMediaData(selectedSpoofReport)
  if (reportSocialMediaData) {
    const socialMediaId = reportSocialMediaData.id
    const screenshotUrl = reportSocialMediaData.profile_image_url

    // Following method doesn't really do much since there's inconsistency between which bucket is used for social media data
    const versionedName = verifyVersionedName(bucketName, screenshotUrl, socialMediaId)

    // If screenshot_url is set, use bucket and ID from it. Otherwise use social media ID
    const { bucketName: socialMediaBucketName, objectName: socialMediaObjectName } =
      screenshotUrl
        ? getBucketNameAndObjectName(screenshotUrl)
        : { bucketName: undefined, objectName: socialMediaId }

    if (versionedName || !verifyNames) {
      return {
        name: socialMediaObjectName,
        table: ScreenshotUrlTable.SOCIAL_MEDIA_DATA,
        existing: !!versionedName,
        bucketName: socialMediaBucketName,
        id: socialMediaId,
      }
    }
  }

  const reportMobileAppData = getReportMobileAppData(selectedSpoofReport)
  if (reportMobileAppData) {
    const mobileAppId = reportMobileAppData.id
    const screenshotUrl = reportMobileAppData.screenshot_url

    const versionedName = verifyVersionedName(bucketName, screenshotUrl, mobileAppId)
    if (versionedName || !verifyNames) {
      return {
        name: mobileAppId,
        table: ScreenshotUrlTable.MOBILE_APPS,
        existing: !!versionedName,
      }
    }
  }

  if (selectedSpoofReport) {
    const reportId = selectedSpoofReport.id
    const screenshotUrl = selectedSpoofReport.screenshot_url

    const versionedName = verifyVersionedName(bucketName, screenshotUrl, reportId)
    if (versionedName || !verifyNames) {
      return {
        name: reportId,
        table: ScreenshotUrlTable.SPOOFING_REPORTS,
        existing: !!versionedName,
      }
    }
  }

  return null
}

export function getVersionedScreenshotName(selectedSpoofReport, bucketName) {
  return getVersionedScreenshotInfo(selectedSpoofReport, bucketName, true)?.name
}

export const isInternalId = (report_id: string): boolean => {
  return uuidValidate(report_id)
}

export const getExternalReportId = (abbr_name: string, external_id: number): string => {
  return `${abbr_name}-${external_id}`
}

export const getDoppelReportUrl = (spoofReport, organization): string => {
  // TODO DOP-4741 should only return external URL
  const orgAbbrName = organization?.abbr_name
  const externalId = spoofReport.external_id
  const reportId = spoofReport.id

  return getDoppelUrlFromId(externalId ? `${orgAbbrName}-${externalId}` : reportId)
}

export const getDoppelUrlFromId = (alertId: string): string => {
  return `${window.location.origin}${PageRoute.ALERTS}/${alertId}`
}

export const filterSpoofingReports = (data, selectedStatus) => {
  // legacy band-aid fix due to read replica lag
  // to prevent reports "staying" in the queue after being moved
  const reports = data?.search_spoofing_reports || data?.spoofing_reports

  if (!reports) {
    return []
  }

  const defaultStatus = ReportStatus.NEEDS_REVIEW
  const statusToFilter = selectedStatus == null ? defaultStatus : selectedStatus

  return reports.filter((report) => report.report_status === statusToFilter)
}

export const generateReportUrl = (
  selectedReportType,
  isOrgUnifiedView,
  selectedSpoofReport,
) => {
  if (!selectedReportType) return null
  let subdomain = isOrgUnifiedView ? PageRoute.UNIFIED_REPORTS : ''
  subdomain += `/${REPORT_TYPE_TO_PATH[selectedReportType]}`

  if (selectedSpoofReport) {
    const orgAbbrName = selectedSpoofReport.organization?.abbr_name
    const externalId = selectedSpoofReport.external_id
    const reportId = selectedSpoofReport.id

    const internalUrl = `${subdomain}/${reportId}`
    const externalUrl = `${subdomain}/${orgAbbrName}-${externalId}`

    // Fallback to internal URL if externalId is null
    return externalId ? externalUrl : internalUrl
  }

  return subdomain
}

export const generateTableViewUrl = (
  subdomain,
  selectedReportFilters,
  selectedReportStatus,
  selectedSortingMechanism,
) => {
  const paramString = computeQueryParams({
    reportFilters: selectedReportFilters,
    reportStatus: selectedReportStatus,
    sortingMechanism: selectedSortingMechanism,
  }).toString()

  return paramString.length ? `${subdomain}?${paramString}` : subdomain
}

export const shouldRenderQueueState = (queueState, isEmployee) => {
  if (isEmployee) {
    return true // Include all statuses if the user is an employee
  }
  // Exclude statuses that contain 'internal' if not an employee
  return !queueState.toLowerCase().includes('internal')
}
