import { ReportFilter, getAllFiltersByType } from '@/hooks/report_table_filters'
import { toSnakeCase } from '@/utils/string_utils'
import {
  ReportSortingMechanism,
  sortingMechanismFromString,
  sortingMechanismToString,
} from './report_sorting_mechanism'
import { ReportFilterType } from '../constants'
import { ReportStatus } from '@/generated/enums'

const LIST_ITEM_DELIMITER = ','

function filterTypeToParamKey(filterType: ReportFilterType): string {
  return toSnakeCase(filterType)
}

type QueryParamDependencies = {
  reportFilters: ReportFilter[]
  reportStatus?: ReportStatus
  sortingMechanism?: ReportSortingMechanism
  page?: number
}

export function computeQueryParams({
  reportFilters,
  reportStatus,
  sortingMechanism,
}: QueryParamDependencies): URLSearchParams {
  const params = new URLSearchParams()
  if (reportStatus) {
    params.append('status', reportStatus)
  }
  if (sortingMechanism) {
    params.append('sort', sortingMechanismToString(sortingMechanism))
  }

  const reportFiltersByType = getAllFiltersByType(reportFilters)
  for (const [type, filters] of Object.entries(reportFiltersByType)) {
    const paramValue = filters.map((filter) => filter.value).join(LIST_ITEM_DELIMITER)
    params.append(filterTypeToParamKey(type as ReportFilterType), paramValue)
  }
  return params
}

export function parseQueryParams(params: URLSearchParams): QueryParamDependencies {
  const reportFilters: ReportFilter[] = []
  for (const filterType of Object.values(ReportFilterType)) {
    const filterKey = filterTypeToParamKey(filterType)
    const filterRawValue = params.get(filterKey)
    if (filterType === ReportFilterType.Search) {
      if (filterRawValue) {
        reportFilters.push({ filterType, value: filterRawValue })
      }
    } else {
      const filterValues = filterRawValue
        ? filterRawValue.split(LIST_ITEM_DELIMITER)
        : []
      for (const filterValue of filterValues) {
        reportFilters.push({ filterType, value: filterValue })
      }
    }
  }
  const reportStatus = params.get('status') as ReportStatus
  const sortString = params.get('sort')
  const pageRawValue = params.get('page')
  const pageValue = /^[1-9]\d*$/.test(pageRawValue) ? parseInt(pageRawValue) : null

  const parsedQueryParams = {
    reportFilters: reportFilters,
    reportStatus: Object.values(ReportStatus).includes(reportStatus)
      ? reportStatus
      : null,
    sortingMechanism: sortingMechanismFromString(sortString ?? ''),
    page: pageValue,
  }
  return parsedQueryParams
}
