import { useAuth0 } from '@auth0/auth0-react'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaExclamationCircle, FaTools, FaUser } from 'react-icons/fa'
import {
  FiBox,
  FiHome,
  FiTrendingUp,
  FiLayers,
  FiSettings,
  FiEye,
} from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaGavel } from 'react-icons/fa'
import Sidebar, { SidebarItem } from '../components/sidebar'
import { getOrgData, useIsEmployeeView } from '../hooks/id_token_claims'
import Login from '../pages/login'
import { DOPPEL_ERROR } from '../utils/style'
import { GiMoneyStack } from 'react-icons/gi'
import posthog from 'posthog-js'

const getSideBarItems = (isEmployeeView, orgData?): SidebarItem[] => {
  const showAnalyticsCredTheft = posthog.isFeatureEnabled('analytics-cred-theft')

  return [
    {
      name: 'Alerts',
      icon: FiHome,
      href: '/',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'Assets',
      icon: FiBox,
      href: '/brands',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'Analytics',
      icon: FiTrendingUp,
      href: showAnalyticsCredTheft ? '/analytics/cred-theft' : '/analytics/brand-abuse',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'PII',
      icon: FiEye,
      href: '/pii',
      isVisible: orgData?.is_pii_detection_enabled,
      isInternal: false,
    },
    {
      name: 'Domain Purchasing',
      icon: GiMoneyStack,
      href: '/domain-purchasing',
      isVisible: orgData?.is_domain_purchasing_enabled,
      isInternal: false,
    },
    {
      name: 'Suspicious Emails',
      icon: MdOutlineEmail,
      href: '/suspicious-emails',
      isVisible: orgData?.is_suspicious_emails_enabled,
      isInternal: false,
    },
    {
      name: 'Organization',
      icon: FiLayers,
      href: '/users',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'App Settings',
      icon: FiSettings,
      href: '/app-settings',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'Admin',
      icon: FaUser,
      href: '/admin/organizations',
      isVisible: isEmployeeView,
      isInternal: true,
    },
    {
      name: 'Enforcements',
      icon: FaGavel,
      href: '/enforcements/automated-takedowns',
      isVisible: isEmployeeView,
      isInternal: true,
    },
    {
      name: 'Detection Tooling',
      icon: FaTools,
      href: '/detection_tooling/sourcing_queries',
      isVisible: isEmployeeView,
      isInternal: true,
    },
  ]
}

const ERROR_DELAY = 2000

export default function Layout({ children, padding = 4 }) {
  const { isAuthenticated, isLoading } = useAuth0()
  const [isEmployeeView] = useIsEmployeeView()
  const [orgData] = getOrgData()

  const [sidebarItems, setSidebarItems] = useState([
    ...getSideBarItems(isEmployeeView, orgData),
  ])

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!orgData) {
      const timeoutId = setTimeout(() => {
        setShowError(true)
      }, ERROR_DELAY)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [orgData])

  useEffect(() => {
    setSidebarItems([...getSideBarItems(isEmployeeView, orgData)])
  }, [isEmployeeView, orgData])

  if (!isAuthenticated && !isLoading) {
    return <Login />
  }

  if (isLoading || !orgData) {
    const component = isLoading ? (
      <Spinner />
    ) : (
      <Flex alignItems={'center'}>
        {showError && (
          <>
            <FaExclamationCircle color={DOPPEL_ERROR} size={20} />

            <Text ml={2}>
              {'Email is not currently registered. Contact Doppel support for help.'}
            </Text>
          </>
        )}
      </Flex>
    )

    return (
      <Sidebar
        component={
          <Flex align="center" height="80vh" justify="center" width="100vw">
            {component}
          </Flex>
        }
        sidebarItems={sidebarItems}
      />
    )
  }

  const currentComponent = (
    <Flex justifyContent="flex-start" maxWidth="100%" padding={padding} width="100%">
      {children}
    </Flex>
  )
  return <Sidebar component={currentComponent} sidebarItems={sidebarItems} />
}
