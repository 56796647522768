import * as Sentry from '@sentry/react'
import ErrorPage from './error_page'
import {
  getOrgData,
  useEmail,
  useIsEmployee,
  useIsEmployeeView,
  useUserID,
} from '@/hooks/id_token_claims'

type ErrorBoundaryProps = {
  children: React.ReactNode
  locationTag?: string
}

/**
 * thin wrapper for Sentry's ErrorBoundary
 * must be located somewhere that has access to IdTokenClaims hooks
 */
export const ErrorBoundary = ({ children, locationTag }: ErrorBoundaryProps) => {
  const [orgData] = getOrgData()
  const isEmployee = useIsEmployee()
  const [isEmployeeView] = useIsEmployeeView()
  const [userId] = useUserID()
  const [email] = useEmail()
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('isEmployee', isEmployee)
        scope.setTag('isEmployeeView', isEmployeeView)
        scope.setTag('orgName', orgData?.name)
        scope.setTag('orgId', orgData?.id)
        scope.setTag('email', email)
        scope.setTag('userId', userId)
        if (locationTag) scope.setTag('errorBoundaryLocation', locationTag)
      }}
      fallback={() => <ErrorPage />}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
