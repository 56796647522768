/**
 * This is an AUTOGENERATED FILE generated by shared/enum_generator.py.
 * DO NOT modify this file manually.
 * To update this file, modify shared/enums.json5 and run shared/enum_generator.py.
 */

export enum CollectionReportChangeType {
  ENFORCEMENT_REQUEST_STATUS = "enforcement_request_status",
  IMPORTANCE_SCORE = "importance_score",
  MAX_SIMILARITY_SCORE = "max_similarity_score",
  SPOOF_STATUS = "spoof_status",
  REPORT_STATUS = "report_status",
  SCORE = "score",
  NOTES = "notes",
  INTERNAL_NOTES = "internal_notes",
  REPORT_CREATE = "report_create",
  ALERT_CREATE = "alert_create",
  REPORT_ADD_MATCH = "report_add_match",
  ENFORCEMENT_EMAIL_STATUS = "enforcement_email_status",
  MATCH_STATUS = "match_status",
  BRAND = "brand",
  PLATFORM_SUBTYPE = "platform_subtype",
  TAG_ACTION = "tag_action",
  TAG_ADD = "tag_add",
  TAG_REMOVE = "tag_remove",
  FILE_ACTION = "file_action",
  ADD_LINK = "add_link",
  REMOVE_LINK = "remove_link",
  SEVERITY = "severity",
}

export const CollectionReportChangeTypeLabels: { [key in CollectionReportChangeType]?: string } = {
  [CollectionReportChangeType.ENFORCEMENT_REQUEST_STATUS]: "enforcement_status",
  [CollectionReportChangeType.IMPORTANCE_SCORE]: "importance_score",
  [CollectionReportChangeType.MAX_SIMILARITY_SCORE]: "similarity_score",
  [CollectionReportChangeType.SPOOF_STATUS]: "classification",
  [CollectionReportChangeType.REPORT_STATUS]: "report_status",
  [CollectionReportChangeType.SCORE]: "suspicion_score",
  [CollectionReportChangeType.NOTES]: "notes",
  [CollectionReportChangeType.INTERNAL_NOTES]: "internal_notes",
  [CollectionReportChangeType.REPORT_CREATE]: "report_created",
  [CollectionReportChangeType.ALERT_CREATE]: "alert_create",
  [CollectionReportChangeType.REPORT_ADD_MATCH]: "url_added",
  [CollectionReportChangeType.ENFORCEMENT_EMAIL_STATUS]: "enforcement_email_status",
  [CollectionReportChangeType.MATCH_STATUS]: "url_classification",
  [CollectionReportChangeType.BRAND]: "brand",
  [CollectionReportChangeType.PLATFORM_SUBTYPE]: "platform",
  [CollectionReportChangeType.TAG_ACTION]: "tag_action",
  [CollectionReportChangeType.TAG_ADD]: "tag_add",
  [CollectionReportChangeType.TAG_REMOVE]: "tag_remove",
  [CollectionReportChangeType.FILE_ACTION]: "file_action",
  [CollectionReportChangeType.ADD_LINK]: "add_link",
  [CollectionReportChangeType.REMOVE_LINK]: "remove_link",
  [CollectionReportChangeType.SEVERITY]: "severity",
};

export enum PlatformName {
  APIFY_APP_STORE = "apify_app_store",
  DOMAIN = "domain",
  SOCIAL_MEDIA = "social_media",
  TWITTER = "twitter",
  FACEBOOK = "facebook",
  FACEBOOK_MARKETPLACE = "facebook_marketplace",
  FACEBOOK_WEB = "facebook_web",
  FACEBOOK_ADS = "facebook_ads",
  CLOUDFLARE = "cloudflare",
  INSTAGRAM = "instagram",
  YOUTUBE = "youtube",
  TIKTOK = "tiktok",
  TELCO = "telco",
  REDDIT = "reddit",
  GITHUB = "github",
  MEDIUM = "medium",
  DISCORD = "discord",
  TWITCH = "twitch",
  TELEGRAM = "telegram",
  BITLY = "bitly",
  LINKEDIN = "linkedin",
  LINKTREE = "linktree",
  PINTEREST = "pinterest",
  SNAPCHAT = "snapchat",
  WHATSAPP = "whatsapp",
  WEIBO = "weibo",
  TUMBLR = "tumblr",
  QUORA = "quora",
  SOUNDCLOUD = "soundcloud",
  FLICKR = "flickr",
  DRIBBBLE = "dribbble",
  DEVIANTART = "deviantart",
  FOURSQUARE = "foursquare",
  IMGUR = "imgur",
  MEETUP = "meetup",
  PATREON = "patreon",
  SLIDESHARE = "slideshare",
  SLIDES = "slides",
  SPOTIFY = "spotify",
  VIMEO = "vimeo",
  CHROME_WEB_STORE = "chrome_web_store",
  GOOGLE_PLAY_STORE = "google_play_store",
  APPLE_APP_STORE = "apple_app_store",
  GETJAR_APP_STORE = "getjar_app_store",
  NINEAPPS_APP_STORE = "9apps_app_store",
  NINENINEIMAGES_APP_STORE = "99images_app_store",
  AMAZON_APP_STORE = "amazon_app_store",
  APKAIO_APP_STORE = "apkaio_app_store",
  APKBOG_APP_STORE = "apkbog_app_store",
  APKCOMBO_APP_STORE = "apkcombo_app_store",
  APKDOG_APP_STORE = "apkdog_app_store",
  APKLUST_APP_STORE = "apklust_app_store",
  APK4K_APP_STORE = "apk4k_app_store",
  APKFREE_APP_STORE = "apkfree_app_store",
  APKFUN_APP_STORE = "apkfun_app_store",
  APKFAB_APP_STORE = "apkfab_app_store",
  APKGK_APP_STORE = "apkgk_app_store",
  APKGOLD_APP_STORE = "apkgold_app_store",
  APKHOUSE_APP_STORE = "apkhouse_app_store",
  APKMODY_APP_STORE = "apkmody_app_store",
  APKMONK_APP_STORE = "apkmonk_app_store",
  APKRESULT_APP_STORE = "apkresult_app_store",
  APKSOS_APP_STORE = "apksos_app_store",
  APKSUPPORT_APP_STORE = "apksupport_app_store",
  APKPURE_APP_STORE = "apkpure_app_store",
  APKMIRROR_APP_STORE = "apkmirror_app_store",
  APKTOY_APP_STORE = "apktoy_app_store",
  APPBRAIN_APP_STORE = "appbrain_app_store",
  APPSONWINDOWS_APP_STORE = "appsonwindows_app_store",
  APTOIDE_APP_STORE = "aptoide_app_store",
  CCM_APP_STORE = "ccm_app_store",
  CNET_APP_STORE = "cnet_app_store",
  FILEHIPPO_APP_STORE = "filehippo_app_store",
  OPERA_MOBILE_STORE = "opera_mobile_store",
  CAFEBAZAAR_APP_STORE = "cafebazaar_app_store",
  COMPSMAG_APP_STORE = "compsmag_app_store",
  FILEHORSE_APP_STORE = "filehorse_app_store",
  GAMELOOP_APP_STORE = "gameloop_app_store",
  HAPPYMOD_APP_STORE = "happymod_app_store",
  IODROID_APP_STORE = "iodroid_app_store",
  MALAVIDA_APP_STORE = "malavida_app_store",
  MEMUPLAY_APP_STORE = "memuplay_app_store",
  PCMAC_APP_STORE = "pcmac_app_store",
  PLAYMODS_APP_STORE = "playmods_app_store",
  MICROSOFT_APP_STORE = "microsoft_app_store",
  SOFTFORPC_APP_STORE = "softforpc_app_store",
  SOFTONIC_APP_STORE = "softonic_app_store",
  SOFTSTON_APP_STORE = "softston_app_store",
  STEPRIMO_APP_STORE = "steprimo_app_store",
  UPTODOWN_APP_STORE = "uptodown_app_store",
  GALAXY_APP_STORE = "galaxy_app_store",
  BITQUERY = "bitquery",
  COINALPHA = "coinalpha",
  COINBASE = "coinbase",
  COINBRAIN = "coinbrain",
  COINCARP = "coincarp",
  COINPARE = "coinpare",
  COINPAPRIKA = "coinpaprika",
  COINRANKING = "coinranking",
  CRYPTO = "crypto.com",
  CRYPTORANK = "cryptorank",
  BINANCE = "binance",
  KUCOIN = "kucoin",
  COINMARKETCAP = "coinmarketcap",
  COINGECKO = "coingecko",
  GATE = "gate",
  GECKOTERMINAL = "geckoterminal",
  LOOKSRARE = "looksrare",
  MESSARI = "messari",
  MINTABLE = "mintable",
  NIFTYGATEWAY = "niftygateway",
  OKX = "okx",
  OPENSEA = "opensea",
  POOCOIN = "poocoin",
  RARIBLE = "rarible",
  SUIEXPLORER = "suiexplorer",
  THEBITTIMES = "thebittimes",
  TOKENINSIGHT = "tokeninsight",
  TOFUNFT = "tofunft",
  TROPEE = "tropee",
  UNISWAP = "uniswap",
  WITHKOJI = "withkoji",
  X2Y2 = "x2y2",
  XT = "xt",
  ZEALY = "zealy",
  GOOGLE_ADS = "google_ads",
  BING_ADS = "bing_ads",
  YAHOO_ADS = "yahoo_ads",
  GMAIL = "gmail",
  YAHOO_MAIL = "yahoo_mail",
  BLOGSPOT = "blogspot",
  GOOGLE_DOCS = "google_docs",
  WIXSITE = "wixsite",
  WORDPRESS = "wordpress",
  MAGICEDEN = "magiceden",
  DEBANK = "debank",
  SUBSTACK = "substack",
  CARRD = "carrd",
  ONLYFANS = "onlyfans",
  BEACONS = "beacons",
  LAUNCHMYNFT = "launchmynft",
  TOPAZ = "topaz",
  ARTSTATION = "artstation",
  TINYURL = "tinyurl",
  LINK3 = "link3",
  RAC1 = "rac1",
  PROOF = "proof",
  MUCKRACK = "muckrack",
  ALLMYLINKS = "allmylinks",
  CURIOUSCAT = "curiouscat",
  PAYPAL = "paypal",
  NETLIFY = "netlify",
  KO_FI = "ko-fi",
  ASK_FM = "ask.fm",
  WEEBLY = "weebly",
  STASHH = "stashh",
  TAPLINK = "taplink",
  FANDOM = "fandom",
  WEB_APP = "web.app",
  LIVESMINTNFT = "livesmintnft",
  BIO_LINK = "bio.link",
  ABOUT_ME = "about.me",
  FANSLY = "fansly",
  GOOGLE_DRIVE = "google_drive",
  AMAZON = "amazon",
  BORED_APE_WEAR = "bored_ape_wear",
  CRYPTO_GOODIES = "crypto_goodies",
  EBAY = "ebay",
  ETSY = "etsy",
  REDBUBBLE = "redbubble",
  SHOPIFY = "shopify",
  TEE7DAYS = "tee7days",
  WISH = "wish",
  ZATEENO = "zateeno",
  EMAIL = "email",
  ROBLOX = "roblox",
  DARKWEB = "darkweb",
  CRED_LEAKS = "cred_leaks",
  CREDIT_CARD_LEAKS = "credit_card_leaks",
  API_LEAKS = "api_leaks",
  THREADS = "threads",
  MISC_ECOMMERCE = "misc_ecommerce",
  SHELLIX = "shellix",
  GOOGLE_SITES = "google_sites",
  CRUNCHBASE = "crunchbase",
  WALMART = "walmart",
  GOOGLE_SEARCH = "google_search",
  APIFY_APP_STORES = "apify_app_stores",
  SUSPICIOUS_EMAILS = "suspicious_emails",
}

export enum ReportStatus {
  NEEDS_REVIEW = "needs_review",
  NEEDS_ACTION = "needs_action",
  NO_ACTION = "approved_derivative",
  REPORTED = "reported",
  RESOLVED = "resolved",
  ARCHIVED = "archived",
  INTERNAL_REVIEW = "internal_review",
  INTERNAL_IGNORED = "internal_ignored",
  INTERNAL_ARCHIVED = "internal_archived",
}

export const ReportStatusLabels: { [key in ReportStatus]?: string } = {
  [ReportStatus.NEEDS_REVIEW]: "doppel_review",
  [ReportStatus.NEEDS_ACTION]: "needs_confirmation",
  [ReportStatus.NO_ACTION]: "monitoring",
  [ReportStatus.REPORTED]: "actioned",
  [ReportStatus.RESOLVED]: "taken_down",
  [ReportStatus.ARCHIVED]: "archived",
  [ReportStatus.INTERNAL_REVIEW]: "internal_review",
  [ReportStatus.INTERNAL_IGNORED]: "Internal Bad Match",
  [ReportStatus.INTERNAL_ARCHIVED]: "internal_archived",
};

export enum ReportClassification {
  UNCLASSIFIED = "unclassified",
  DOWN = "down",
  PARKED = "parked",
  UNRELATED = "unrelated",
  RELATED = "related",
  SUSPICIOUS = "suspicious",
  UNKNOWN = "unknown",
  ACTIVE = "active",
}

export enum ReportSource {
  UI_UPLOAD = "ui_upload",
  API_UPLOAD = "doppel_api",
  ABUSE_BOX = "abuse_box",
  GOOGLE_ADS_TRANSPARENCY = "google_ads_transparency",
  TELEGRAM_USER_SEARCH_API = "telegram_user_search_api",
  SHELLIX_LISTING_SEARCH = "shellix_listing_search",
  URLSCAN_IO = "urlscan_io",
  REDDIT_SUBREDDIT_SEARCH = "reddit_subreddit_search",
  ECX = "ecx",
  GOOGLE_SEARCH = "google_search",
  WHOISXML_SUBDOMAIN = "whoisxml-subdomain",
  OPENPHISH = "openphish",
  TWITTER_TWEET_SEARCH_API_V2 = "twitter_tweet_search_api_v2",
  ROBLOX_LISTING_SEARCH = "roblox_listing_search",
  TWITTER_USER_SEARCH_API_V1 = "twitter_user_search_api_v1",
  YOUTUBE_CHANNEL_SEARCH = "youtube_channel_search",
  DISCORD_CHANNEL_SEARCH = "discord_channel_search",
  SHELLIX_SEARCH = "shellix_search",
  SECURITY_TRAILS_SEARCH = "security_trails_search",
  TWITTER_API = "twitter_api",
  INSTAGRAM_USER_SCRAPING_APIFY_V0 = "instagram_user_scraping_apify_v0",
  MANUAL_UPLOAD = "manual_upload",
  TWITTER_USER_SCRAPING_APIFY_V0 = "twitter_user_scraping_apify_v0",
  HISTORICAL_REGISTRATION = "historical_registration",
  DARKOWL_SEARCH = "DARKOWL_SEARCH",
  YOUTUBE_LIVE_VIDEO_SEARCH = "youtube_live_video_search",
  DOMAIN_BACKTEST = "domain_backtest",
  DOMAIN_CHALK_EVALUATION = "domain_chalk_evaluation",
  DOMAINS_MONITOR = "domains-monitor",
  TWITTER_TWEET_REPLY_DOMAINS = "twitter_tweet_reply_domains",
  SERP_API = "serp-api",
  SHODAN_SEARCH = "shodan_search",
  TIKTOK_USER_SCRAPING_APIFY_V0 = "tiktok_user_scraping_apify_v0",
  WHOISXML_DOMAIN = "whoisxml-domain",
  MANUAL_SEARCH = "manual_search",
  GOOGLE_SEARCH_ADS = "google_search_ads",
  TWITTER_TWEET_SEARCH_API_V1 = "twitter_tweet_search_api_v1",
  APP_STORE_SCRAPING_APIFY_V0 = "app_store_scraping_apify_v0",
  DARKOWL_EMAIL = "DARKOWL_EMAIL",
  DARKOWL_RANSOMWARE = "DARKOWL_RANSOMWARE",
  INTELX_EMAIL_DOMAIN_API = "INTELX_EMAIL",
  DARKOWL_BIN_API = "DARKOWL_BIN_API",
  DARKOWL_API_LEAKS = "DARKOWL_API_LEAKS",
  PHISH_REPORT = "phish_report",
  CLONED_WEBSITE_EVENT = "cloned_website_event",
  TWITTER_USER_SEARCH_API_V2 = "twitter_user_search_api_v2",
  PHISHING_DATABASE = "phishing-database",
  WHOIS = "whois",
  DOCUMENT_REQUEST = "DOCUMENT_REQUEST",
  INSTAGRAM_USER_SCRAPING = "instagram_user_scraping",
  CERTIFICATE_TRANSPARENCY_LOG = "certificate_transparency_log",
  WHOISXML_STREAMING = "whoisxml_streaming",
  DAILY_REGISTRATION = "daily_registration",
  URLSCAN_IO_TO_PHISHING_FEED = "urlscan_io_phishing_feed",
  URLSCAN_IO_IP_PIVOTING = "urlscan_io_ip_pivoting",
  DNSTWIST = "dnstwist",
  VETRIC = "vetric",
  DOMAINTOOLS = "domaintools",
  FACEBOOK_ADS_APIFY = "facebook_ads_apify",
  VIRUSTOTAL = "virustotal",
  EMAIL_INBOX = "email_inbox",
  DOMAIN_TOOLS_IRIS_INVESTIGATE = "domain_tools_iris_investigate",
  DELIST_MONITORING = "delist_monitoring",
  BING_ADS_LIBRARY = "bing_ads_library",
}

export const ReportSourceLabels: { [key in ReportSource]?: string } = {
  [ReportSource.UI_UPLOAD]: "analyst_upload",
  [ReportSource.API_UPLOAD]: "analyst_upload",
  [ReportSource.ABUSE_BOX]: "abuse_box",
  [ReportSource.GOOGLE_ADS_TRANSPARENCY]: "ads_feed",
  [ReportSource.TELEGRAM_USER_SEARCH_API]: "social_media_api",
  [ReportSource.SHELLIX_LISTING_SEARCH]: "darkweb_feed",
  [ReportSource.URLSCAN_IO]: "web_search_api",
  [ReportSource.REDDIT_SUBREDDIT_SEARCH]: "social_media_api",
  [ReportSource.ECX]: "phishing_feed",
  [ReportSource.GOOGLE_SEARCH]: "web_search_api",
  [ReportSource.WHOISXML_SUBDOMAIN]: "domains_feed",
  [ReportSource.OPENPHISH]: "phishing_feed",
  [ReportSource.TWITTER_TWEET_SEARCH_API_V2]: "social_media_api",
  [ReportSource.ROBLOX_LISTING_SEARCH]: "marketplace_api",
  [ReportSource.TWITTER_USER_SEARCH_API_V1]: "social_media_api",
  [ReportSource.YOUTUBE_CHANNEL_SEARCH]: "social_media_api",
  [ReportSource.DISCORD_CHANNEL_SEARCH]: "social_media_api",
  [ReportSource.SHELLIX_SEARCH]: "darkweb_feed",
  [ReportSource.SECURITY_TRAILS_SEARCH]: "web_search_api",
  [ReportSource.TWITTER_API]: "social_media_api",
  [ReportSource.INSTAGRAM_USER_SCRAPING_APIFY_V0]: "social_media_api",
  [ReportSource.MANUAL_UPLOAD]: "analyst_upload",
  [ReportSource.TWITTER_USER_SCRAPING_APIFY_V0]: "social_media_api",
  [ReportSource.HISTORICAL_REGISTRATION]: "web_search_api",
  [ReportSource.DARKOWL_SEARCH]: "darkweb_feed",
  [ReportSource.YOUTUBE_LIVE_VIDEO_SEARCH]: "social_media_api",
  [ReportSource.DOMAIN_BACKTEST]: "other",
  [ReportSource.DOMAIN_CHALK_EVALUATION]: "other",
  [ReportSource.DOMAINS_MONITOR]: "domains_feed",
  [ReportSource.TWITTER_TWEET_REPLY_DOMAINS]: "social_media_api",
  [ReportSource.SERP_API]: "web_search_api",
  [ReportSource.SHODAN_SEARCH]: "web_search_api",
  [ReportSource.TIKTOK_USER_SCRAPING_APIFY_V0]: "social_media_api",
  [ReportSource.WHOISXML_DOMAIN]: "domains_feed",
  [ReportSource.MANUAL_SEARCH]: "analyst_upload",
  [ReportSource.GOOGLE_SEARCH_ADS]: "ads_feed",
  [ReportSource.TWITTER_TWEET_SEARCH_API_V1]: "social_media_api",
  [ReportSource.APP_STORE_SCRAPING_APIFY_V0]: "marketplace_api",
  [ReportSource.DARKOWL_EMAIL]: "darkweb_feed",
  [ReportSource.DARKOWL_RANSOMWARE]: "darkweb_feed",
  [ReportSource.INTELX_EMAIL_DOMAIN_API]: "darkweb_feed",
  [ReportSource.DARKOWL_BIN_API]: "darkweb_feed",
  [ReportSource.DARKOWL_API_LEAKS]: "darkweb_feed",
  [ReportSource.PHISH_REPORT]: "phishing_feed",
  [ReportSource.CLONED_WEBSITE_EVENT]: "phishing_feed",
  [ReportSource.TWITTER_USER_SEARCH_API_V2]: "social_media_api",
  [ReportSource.PHISHING_DATABASE]: "phishing_feed",
  [ReportSource.WHOIS]: "domains_feed",
  [ReportSource.DOCUMENT_REQUEST]: "darkweb_feed",
  [ReportSource.INSTAGRAM_USER_SCRAPING]: "social_media_api",
  [ReportSource.CERTIFICATE_TRANSPARENCY_LOG]: "certificate_transparency_log",
  [ReportSource.WHOISXML_STREAMING]: "domains_feed",
  [ReportSource.DAILY_REGISTRATION]: "domains_feed",
  [ReportSource.URLSCAN_IO_TO_PHISHING_FEED]: "web_search_api",
  [ReportSource.URLSCAN_IO_IP_PIVOTING]: "pivot",
  [ReportSource.DNSTWIST]: "phishing_feed",
  [ReportSource.VETRIC]: "social_media_api",
  [ReportSource.DOMAINTOOLS]: "domains_feed",
  [ReportSource.FACEBOOK_ADS_APIFY]: "ads_feed",
  [ReportSource.VIRUSTOTAL]: "domains_feed",
  [ReportSource.EMAIL_INBOX]: "email_inbox",
  [ReportSource.DOMAIN_TOOLS_IRIS_INVESTIGATE]: "domains_feed",
  [ReportSource.DELIST_MONITORING]: "other",
  [ReportSource.BING_ADS_LIBRARY]: "ads_feed",
};

export enum ReportSourceCategory {
  ANALYST_UPLOAD = "analyst_upload",
  SOCIAL_MEDIA_API = "social_media_api",
  WEB_SEARCH_API = "web_search_api",
  DOMAINS_FEED = "domains_feed",
  PHISHING_FEED = "phishing_feed",
  MARKETPLACE_API = "marketplace_api",
  ADS_FEED = "ads_feed",
  DARKWEB_FEED = "darkweb_feed",
  CERTIFICATE_TRANSPARENCY_LOG = "certificate_transparency_log",
  PIVOT = "pivot",
  ABUSE_BOX = "abuse_box",
  OTHER = "other",
  EMAIL_INBOX = "email_inbox",
}

export const ReportSourceCategoryLabels: { [key in ReportSourceCategory]?: string } = {
  [ReportSourceCategory.ANALYST_UPLOAD]: "Analyst Upload",
  [ReportSourceCategory.SOCIAL_MEDIA_API]: "Social Media API",
  [ReportSourceCategory.WEB_SEARCH_API]: "Web Search API",
  [ReportSourceCategory.DOMAINS_FEED]: "Domains Feed",
  [ReportSourceCategory.PHISHING_FEED]: "Phishing Feed",
  [ReportSourceCategory.MARKETPLACE_API]: "Marketplace API",
  [ReportSourceCategory.ADS_FEED]: "Ads Feed",
  [ReportSourceCategory.DARKWEB_FEED]: "Dark Web Feed",
  [ReportSourceCategory.CERTIFICATE_TRANSPARENCY_LOG]: "SSL Transparency Logs",
  [ReportSourceCategory.PIVOT]: "Pivot",
  [ReportSourceCategory.ABUSE_BOX]: "Abuse Box",
  [ReportSourceCategory.OTHER]: "Other",
  [ReportSourceCategory.EMAIL_INBOX]: "Email Inbox",
};

export enum MissedCaseBuckets {
  NOT_SOURCED = "not_sourced",
  SOURCED_AFTER_MISSED_CASE_CREATION = "sourced_after_missed_case_creation",
  SOURCED_BEFORE_MISSED_CASE_CREATION = "sourced_before_missed_case_creation",
}

export enum PageRoute {
  ALERTS = "/alerts",
  DOMAINS = "/domains",
  TRADEMARKS = "/trademarks",
  BRANDS = "/brands",
  ORGANIZATIONS = "/admin/organizations",
  UNIFIED_REPORTS = "/admin/reports",
  AUTOMATED_TAKEDOWNS = "/enforcements/automated-takedowns",
  ENFORCEMENT_TEMPLATES = "/enforcements/enforcement-templates",
  ENFORCEMENT_PLATFORMS = "/enforcements/enforcement-platforms",
  AI_PROMPTS = "/admin/ai-prompts",
  SOURCING_QUERIES = "/detection_tooling/sourcing_queries",
  SCRAPING_CONFIGS = "/detection_tooling/scraping_configs",
  DETECTION_ASSETS = "/detection_tooling/detection_assets",
  DETECTION_RULES = "/detection_tooling/detection_rules",
  MISSED_CASES = "/detection_tooling/missed_cases",
  IOCS = "/detection_tooling/iocs",
  ENFORCEMENT_FORM_FIELDS = "/enforcements/form-fields",
}

export enum CloudflareReportAbuseErrorCode {
  DUPLICATE = "dedupe",
  URL_NOT_ORANGE = "url_not_orange",
}

export enum AlertSeverity {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export enum EnforcementRequestPlatform {
  METAMASK = "metamask",
  PHANTOM = "phantom",
  GOOGLE = "google",
  COINBASE = "coinbase",
  PUBLIC_DOMAIN_REGISTRY = "public_domain_registry",
  TWITTER = "twitter",
  CLOUDFLARE = "cloudflare",
  TIKTOK = "tiktok",
  GODADDY = "godaddy",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  WHATSAPP = "whatsapp",
  NAMECHEAP = "namecheap",
  YOUTUBE = "youtube",
  CLEANDNS = "cleandns",
}

export enum DomainEnforcementPlatform {
  NAMECHEAP = "namecheap",
  NAMESILO = "namesilo",
  TUCOWS = "tucows",
  PORKBUN = "porkbun",
  PUBLIC_DOMAIN_REGISTRY = "public_domain_registry",
  HOSTINGER = "hostinger",
  SAV = "sav",
  GNAME = "gname",
  DYNADOT = "dynadot",
  NICENIC = "nicenic",
  SAREK_OY = "sarek_oy",
  EPIK = "epik",
  GODADDY = "godaddy",
  CLOUDFLARE = "cloudflare",
  GOOGLE = "google",
  CLEANDNS = "cleandns",
  VERCEL = "vercel",
}

export enum BulkSubmissionExcludedPlatforms {
  GOOGLE_PLAY_STORE = "google_play_store",
  APPLE_APP_STORE = "apple_app_store",
}

export enum EnforcementRequestStatus {
  FAILED = "failed",
  STAGED = "staged",
  BLOCKED = "blocked",
  REPORTED = "reported",
  APPROVED = "approved",
  REJECTED = "rejected",
  REROUTED = "rerouted",
  CANCELED = "canceled",
  RETRACTION_SENT = "retraction_sent",
  RETRACTED = "retracted",
}

export enum NamecheapAntiPhishingCaseStatus {
  NEW = 0,
  MANUAL_PROCESSING = 1,
  AUTO_PROCESSED = 2,
  FALSE_POSITIVE = 5,
  FAILED = 6,
  EARLIER_SUSPENDED = 7,
}

export enum WebRiskOperationStatus {
  SUCCEEDED = "SUCCEEDED",
  CLOSED = "CLOSED",
  RUNNING = "RUNNING",
  FAILED = "FAILED",
}

export enum CleanDnsCaseStatus {
  WITH_TARGET = "With Target",
  CLOSED = "Closed",
  SUGGESTED = "Suggested",
  CANCELED = "Canceled",
  EDITING = "Editing",
  DISPOSITION_QUEUE = "Disposition Queue",
  CLIENT_REVIEW = "Client Review",
}

export enum GodaddyCaseStatus {
  RECLASSIFIED = "RECLASSIFIED",
  NOT_REGISTERED_HOSTED_HERE = "NOT_REGISTERED_HOSTED_HERE",
  FORWARDED_COMPLAINT = "FORWARDED_COMPLAINT",
  FALSE_POSITIVE = "FALSE_POSITIVE",
  ACTIONED = "ACTIONED",
}

export enum ReportSortType {
  CREATED_AT = "created_at",
  DATE_LAST_ACTIONED = "date_last_actioned",
}

export const ReportSortTypeLabels: { [key in ReportSortType]?: string } = {
  [ReportSortType.CREATED_AT]: "date_sourced",
  [ReportSortType.DATE_LAST_ACTIONED]: "date_last_actioned",
};

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum EnforcementEmailType {
  CONFIRMATION = "confirmation",
  INFO_REQUESTED = "info_requested",
  FOLLOW_UP = "follow_up",
  REPLY = "reply",
  ACCEPTANCE = "acceptance",
  REJECTION = "rejection",
  UNKNOWN = "unknown",
  INITIAL_REQUEST = "initial_request",
  REROUTED = "rerouted",
}

export enum EnforcementEmailStatus {
  RECEIVED = "received",
  SENT = "sent",
}

export enum EnforcementEmailStage {
  INITIAL = "initial",
  FOLLOW_UP = "follow_up",
}

export enum ReportChangeEventSource {
  UI = "ui",
  BULK_UI = "bulk_ui",
  DOPPEL_API = "doppel_api",
  DOMAIN_REGEX_RULE = "domain_regex_rule",
  PROFILE_IMAGE_HASH_SIMILARITY = "profile_image_hash_similarity",
  PHISH_REPORT_DETECTION = "phish_report_detection",
  HTML_TITLE_SIMILARITY = "html_title_similarity",
  AUTO_EXPIRE_INTERNAL_ARCHIVE = "auto_expire_internal_archive",
  DELIST_MONITORING = "delist_monitoring",
  REVIVAL_MONITORING = "revival_monitoring",
  SOCIAL_MEDIA_RECLASSIFICATION = "social_media_reclassification",
  DOMAIN_CLASSIFICATION = "domain_classification",
  PROTECTED_ASSET_MATCH = "protected_asset_match",
  PLATFORM_REPORT_REPROCESSING = "platform_report_reprocessing",
  EMAIL_MONITORING = "email_monitoring",
  TRUSTED_REGISTRAR = "trusted_registrar",
  IR_TO_DR_RULE = "ir_to_dr_rule",
  THREAT_TYPE_CLASSIFIER = "threat_type_classifier",
  ALERT_LINKING = "alert_linking",
  JIRA = "jira",
  ALERT_SIMILARITY = "alert_similarity",
  ALERT_QUEUE_DECISION = "alert_queue_decision",
  IOC_MATCH = "ioc_match",
  DOMAIN_DETECTION_RULE = "domain_detection_rule",
  ALERT_CREATION = "alert_creation",
  AUTO_ARCHIVE = "auto_archive",
}

export enum FeatureName {
  SITE_TEXT_TFIDF = "site_text_tfidf",
  DOMAIN_REGISTRAR = "domain_registrar",
  NYCKEL_vafrxvqki46lniiu = "nyckel_vafrxvqki46lniiu",
  NYCKEL_b29ghz4sunis0g34 = "nyckel_b29ghz4sunis0g34",
  NYCKEL_uflpm9h9c3y7ec1d = "nyckel_uflpm9h9c3y7ec1d",
  NYCKEL_w1d3vdy83w8f8mj5 = "nyckel_w1d3vdy83w8f8mj5",
  XGBOOST = "XGBoostAutoGeneratedFeature",
  ORGANIZATION_ID = "organization_id",
  IS_DETECTION_ASSET_URL_GENERIC = "is_detection_asset_url_generic",
  SITE_TEXT_REGEX = "site_text_regex",
  SITE_URL_REGEX = "site_url_regex",
  SITE_HTML_REGEX = "site_html_regex",
  SCREENSHOT_SIMILARITY = "screenshot_similarity",
  HTML_BRAND_LOGO_SIMILARITY = "html_brand_logo_similarity",
  HTML_FAVICON_SIMILARITY = "html_favicon_similarity",
  HTML_TITLE_SIMILARITY = "html_title_similarity",
  HTML_SSDEEP_HASH_SIMILARITY = "html_ssdeep_hash_similarity",
  URL_SIMILARITY_SECOND_LEVEL_DOMAIN_MATCH = "url_similarity_second_level_domain_match",
  URL_SIMILARITY_DOMAIN_SEARCH = "url_similarity_domain_search",
  URL_URL_SIMILARITY = "url_url_similarity",
  URL_FUZZY_KEYWORD_SEARCH = "url_fuzzy_keyword_search",
  URL_KEYWORD_SEARCH = "url_keyword_search",
  PHISH_REPORT_DETECTION = "phish_report_detection",
  HTML_FORM_DETECTION = "html_form_detection",
  TELEGRAM_LINK_DETECTION = "telegram_link_detection",
  URL_REDIRECT_DETECTION = "url_redirect_detection",
  CRYPTO_WALLET_CONNECT_DETECTION = "crypto_wallet_connection_detection",
  SOCIAL_MEDIA_DISPLAY_NAME_SIMILARITY = "social_media_user_display_name_similarity",
  SOCIAL_MEDIA_USERNAME_SIMILARITY = "social_media_user_username_similarity",
  SOCIAL_MEDIA_USER_PROFILE_IMAGE_SIMILARITY = "social_media_user_profile_image_similarity",
  SOCIAL_MEDIA_USERNAME_BRAND_NAME_SIMILIARITY = "social_media_username_display_name_similarity",
  PROTECTED_ASSET_SLUG_SIMILARITY = "protected_asset_slug_similarity",
  SLUG_SIMILARITY = "slug_similarity",
  PROFILE_NAME_SIMILARITY = "profile_name_similarity",
  SITE_TITLE = "site_title",
  GTAG = "gtag",
  FACEBOOK_TRACKING_PIXEL = "facebook_tracking_pixel",
  MALICIOUS_TRACKING_TAG = "malicious_tracking_tag",
  IP_ADDRESS_MALICIOUSNESS = "malicious_source_ip",
  MALICIOUS_SITE_TITLE = "malicious_site_title",
  URL_SIMILARITY_FILTERING = "url_similarity_filtering",
  URL_SIMILARITY_RANKING = "url_similarity_ranking",
  SITE_TEXT_DIFF_LIB = "site_text_diff_lib",
  TEST = "test",
  AGE = "age",
  WEIGHT = "weight",
  LOOKS_LIKE = "looks_like",
  SMELLS_LIKE = "smells_like",
  JUMP_SCORE = "jump_score",
  CUTENESS = "cuteness",
}

export enum DetectionAssetType {
  BRAND_LOGO = "brand_logo",
  REGEX_SITE_TEXT = "regex_site_text",
  REGEX_URL = "regex_url",
  REGEX_HTML = "regex_html",
  SITE_FAVICON = "site_favicon",
  SITE_SCREENSHOT = "site_screenshot",
  FULL_SITE_SCREENSHOT = "full_site_screenshot",
  MOBILE_SITE_SCREENSHOT = "mobile_site_screenshot",
  MOBILE_FULL_SITE_SCREENSHOT = "mobile_full_site_screenshot",
  SITE_TEXT = "site_text",
  SITE_HTML = "site_html",
  SITE_HTML_SSDEEP_HASH = "site_html_ssdeep_hash",
  SITE_TITLE = "site_title",
  MOBILE_SITE_TEXT = "mobile_site_text",
  MOBILE_SITE_HTML = "mobile_site_html",
  MOBILE_SITE_HTML_SSDEEP_HASH = "mobile_site_html_ssdeep_hash",
  MOBILE_SITE_TITLE = "mobile_site_title",
  URL_SIMILARITY_URL = "url_similarity_url",
  URL_SIMILARITY_KEYWORD = "url_similarity_keyword",
  URL_SIMILARITY_FUZZY_KEYWORD = "url_similarity_fuzzy_keyword",
  CUSTOM_RULE = "custom_rule",
  IP_ADDRESS = "ip_address",
  SOCIAL_MEDIA_USER_DISPLAY_NAME = "social_media_user_display_name",
  SOCIAL_MEDIA_USER_PROFILE_IMAGE = "social_media_user_profile_image",
  SOCIAL_MEDIA_USER_USERNAME = "social_media_user_username",
  SOCIAL_MEDIA_USER_BIO = "social_media_user_bio",
  BRAND_NAME = "brand_name",
  GTAG = "gtag",
  FACEBOOK_TRACKING_PIXEL = "facebook_tracking_pixel",
}

export enum DetectionAssetEnabledUsage {
  BRAND_LOGO_DETECTION = "brand_logo_detection",
  SITE_TEXT_FEATURE = "site_text_feature",
  SITE_FAVICON_DETECTION = "site_favicon_detection",
  SITE_SCREENSHOT_DETECTION = "site_screenshot_detection",
  SITE_TEXT_DETECTION = "site_text_detection",
  SITE_HTML_DETECTION = "site_html_detection",
  SITE_TITLE_DETECTION = "site_title_detection",
  URL_SIMILARITY_DETECTION = "url_similarity_detection",
  CUSTOM_RULE_DETECTION = "custom_rule_detection",
  SITE_URL_DETECTION = "site_url_detection",
  IP_ADDRESS_DETECTION = "ip_pivoting",
  SOCIAL_MEDIA_USER_DISPLAY_NAME_DETECTION = "social_media_user_display_name_similarity_detection",
  SOCIAL_MEDIA_USER_PROFILE_IMAGE_DETECTION = "social_media_user_profile_image_similarity_detection",
  SOCIAL_MEDIA_USER_BIO_DETECTION = "social_media_user_bio_similarity_detection",
  SOCIAL_MEDIA_USER_USERNAME_DETECTION = "social_media_user_username_similarity_detection",
  SOCIAL_MEDIA_BRAND_NAME_DETECTION = "social_media_brand_name_detection",
  IOC_PIVOTING = "ioc_pivoting",
  CRED_THEFT_DETECTION = "cred_theft_detection",
}

export enum DetectionAssetOriginatorType {
  BRAND = "brand",
  PROTECTED_ASSET = "protected_asset",
  USER = "user",
  IOC = "ioc",
  FULL_URL = "full_url",
  SOCIAL_MEDIA = "social_media",
  MOBILE_APP = "mobile_app",
  ALERT = "alert",
}

export enum DetectionAssetIrToDrRule {
  DISABLED = "disabled",
  EXACT_MATCH = "exact_match",
  PARTIAL_MATCH_95 = "partial_match_95",
}

export enum DetectionAssetCreationMethod {
  AUTO_GENERATED_FROM_BRAND = "auto_generated_from_brand",
  AUTO_GENERATED_FROM_IOC = "auto_generated_from_ioc",
  AUTO_GENERATED_FROM_PROTECTED_ASSET = "auto_generated_from_protected_asset",
  AUTO_GENERATED_FROM_ALERT = "auto_generated_from_alert",
  UI_URL_UPLOAD = "ui_url_upload",
  USER_UPLOAD_FROM_FILE = "user_upload_from_file",
}

export enum CustomerType {
  STANDARD = "standard",
  CRYPTO = "crypto",
}

export enum PubSubTopic {
  DELIST_MONITORING = "delist_monitoring",
  SOURCING = "sourcing",
  DOMAIN_SIGNAL_PROCESSING = "domain_signal_processing",
  DOMAIN_CANDIDATE_PROCESSING = "domain_candidate_processing",
  ENFORCEMENT_STATUS_RESOLUTION = "enforcement_status_resolution",
  EMAIL_MONITORING = "email_monitoring",
  AUTO_REPORTING_VALIDATION = "auto_reporting_validation",
  AUTO_REPORTING_SUBMISSION = "auto_reporting_submission",
}

export enum CloudTaskQueue {
  DELIST_CLOUD_TASKS_QUEUE = "delist-monitoring",
  SOURCING_CLOUD_TASKS_QUEUE = "sourcing",
  DOMAIN_THROTTLER_TASKS_QUEUE = "domain-throttler",
  ENFORCEMENT_STATUS_RESOLUTION = "enforcement-status-resolution",
  EMAIL_MONITORING = "email-monitoring",
  AUTOREPORTING_VALIDATION = "auto-reporting-validation",
}

export enum DelistSelectionMethod {
  CLOUD_TASK = "cloud_task",
  PUBSUB = "pubsub",
}

export enum AutoStagingDisabledMultiplePlatforms {
  ALL = "cleanDNS and all auto-staging",
  ALL_CRYPTO = "all crypto wallet blocking",
}

export enum AutoStagingDisabledReason {
  PRODUCT_TYPE_NOT_DOMAIN = "auto-staging not enabled for this product. Please continue creating enforcements as normal using the established playbook",
  FLAGGED_URL_HAS_PATH = "flagged url has a path. Please report phishing directly to the host domain/platform of the flagged url",
  FLAGGED_URL_HAS_EXACT_PLATFORM = "flagged url is an exact match with a platform domain. Please do not generate any enforcements (manual or auto) for this report",
  ENFORCEMENT_TYPE_NOT_PHISHING = "enforcement type is not phishing. If you wish to auto-stage phishing enforcements, please change enforcement type to phishing",
  ENFORCMENT_METADATA_NO_LEGAL_NAME = "enforcement metadata does not have legal_name. Please add the legal name for the organization using the admin organizations page",
  FLAGGED_URL_HAS_PLATFORM = "flagged url's root or host is a platform domain. Please create a phishing enforcement for the matching platform",
  DISABLED_HOST_OR_REGISTRAR = "auto-staging is disabled for this host or registrar. Please manually stage phishing enforcements requests to the host and/or registrar",
  NOT_CRYPTO_CUSTOMER = "customer type is not crypto. To switch customer type, use admin organizations page",
  PLATFORM_ENFORCEMENT_ALREADY_ACTIVE = "active enforcement already exists for the platform. Consider checking on the status of the existing request for the platform or blocking and re-reporting",
  ENFORCEMENT_ALREADY_STAGED = "enforcement already staged for the platform in the Domains Takedown Request Form. Please continue reviewing enforcements as normal",
  IP_ADDRESS_FOUND = "flagged url contains IP address. No further action required",
}

export enum AutoStagingDisabledParamName {
  FLAGGED_URL = "flagged url",
  MATCHING_PLATFORM = "matching platform",
  DETECTED_PATH = "detected path",
  CUSTOMER_TYPE = "customer type",
  PRODUCT_TYPE = "product type",
  ENFORCEMENT_TYPE = "enforcement type",
  HOSTING_PROVIDER = "hosting provider",
  REGISTRAR_NAME = "registrar name",
  HOST_DOMAIN = "host domain",
  URL_TYPE = "url type",
  SUPPORTED_URL_TYPES = "supported url types",
}

export enum StatsDateFilters {
  LAST_DAY = "last_day",
  LAST_WEEK = "last_week",
  LAST_MONTH = "last_month",
  LAST_3_MONTHS = "last_3_months",
  LAST_6_MONTHS = "last_6_months",
}

export const StatsDateFiltersLabels: { [key in StatsDateFilters]?: string } = {
  [StatsDateFilters.LAST_DAY]: "Last 24 Hours",
  [StatsDateFilters.LAST_WEEK]: "Last 7 Days",
  [StatsDateFilters.LAST_MONTH]: "Last 30 Days",
  [StatsDateFilters.LAST_3_MONTHS]: "Last 3 Months",
  [StatsDateFilters.LAST_6_MONTHS]: "Last 6 Months",
};

export enum StatsDateBucketInterval {
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum SourcerLabel {
  DOMAINS_DOMAIN_MONITOR = "domains_domain_monitor",
  DOMAINS_DOMAIN_OPENPHISH = "domains_domain_openphish",
  DOMAINS_DOMAIN_PHISHING_DATABASE = "domains_domain_phishing_database",
  DOMAINS_DOMAIN_SECURITY_TRAILS_SEARCH = "domains_domain_security_trails_search",
  DOMAINS_DOMAIN_URLSCAN_IO_KEYWORD = "domains_domain_urlscan_io_keyword",
  DOMAINS_DOMAIN_URLSCAN_IO_QUERY = "domains_domain_urlscan_io_query",
  DOMAINS_DOMAIN_WHOIS_DOMAIN = "domains_domain_whois_domain",
  DOMAINS_DOMAIN_WHOISXML_DOMAIN = "domains_domain_whoisxml_domain",
  DOMAINS_DOMAIN_WHOISXML_SUBDOMAIN = "domains_domain_whoisxml_subdomain",
  DOMAINS_DOMAIN_GOOGLE_SEARCH = "domains_domain_google_search",
  DOMAINS_DOMAIN_ECX = "domains_domain_ecx",
  DOMAINS_DOMAIN_SHODAN_SEARCH = "domains_domain_shodan_search",
  DOMAINS_DOMAIN_URLSCAN_IO_PHISHING_FEED = "domains_domain_urlscan_io_phishing_feed",
  DOMAINS_DOMAIN_DNSTWIST = "domains_domain_dnstwist",
  DOMAINS_DOMAIN_VIRUSTOTAL_URL_FEED = "domains_domain_virustotal_url_feed",
  DOMAINS_DOMAIN_TOOLS_IRIS_INVESTIGATE = "domains_domain_tools_iris_investigate",
  MOBILE_APPS_GOOGLE_PLAY_STORE_SERP = "mobile_apps_google_play_store_serp",
  MOBILE_APPS_APPLE_APP_STORE_SERP = "mobile_apps_apple_app_store_serp",
  MOBILE_APPS_APIFY_APP_STORES_APIFY = "mobile_apps_apify_app_stores_apify",
  SOCIAL_MEDIA_TIKTOK_APIFY = "social_media_tiktok_apify",
  SOCIAL_MEDIA_TWITTER_USER_SEARCH = "social_media_twitter_user_search",
  SOCIAL_MEDIA_YOUTUBE_CHANNEL_SEARCH = "social_media_youtube_channel_search",
  SOCIAL_MEDIA_YOUTUBE_LIVE_VIDEO_SEARCH = "social_media_youtube_live_video_search",
  SOCIAL_MEDIA_TWITTER_VETRIC = "social_media_twitter_vetric",
  SOCIAL_MEDIA_FACEBOOK_VETRIC = "social_media_facebook_vetric",
  SOCIAL_MEDIA_FACEBOOK_PAGE_VETRIC = "social_media_facebook_page_vetric",
  SOCIAL_MEDIA_INSTAGRAM_VETRIC = "social_media_instagram_vetric",
  SOCIAL_MEDIA_INSTAGRAM_ACCOUNTS_VETRIC = "social_media_instagram_accounts_vetric",
  SOCIAL_MEDIA_TWITTER_POSTS_LATEST_VETRIC = "social_media_twitter_posts_latest_vetric",
  SOCIAL_MEDIA_TWITTER_POSTS_MONITOR_TWEET_REPLIES = "social_media_twitter_posts_monitor_tweet_replies",
  SOCIAL_MEDIA_TWITTER_POSTS_MONITOR_PROTECTED_ACCOUNT = "social_media_twitter_posts_monitor_protected_account",
  SOCIAL_MEDIA_FACEBOOK_POSTS = "social_media_facebook_posts",
  DARKWEB_DARKWEB_DARKOWL_EMAIL = "darkweb_darkweb_darkowl_email",
  DARKWEB_DARKWEB_DARKOWL_RANSOMWARE = "darkweb_darkweb_darkowl_ransomware",
  DARKWEB_DARKWEB_DARKOWL_SEARCH = "darkweb_darkweb_darkowl_search",
  DARKWEB_DARKWEB_DARKOWL_API_LEAKS = "darkweb_darkweb_darkowl_api_leaks",
  DARKWEB_DARKWEB_INTELX_EMAIL = "darkweb_darkweb_intelx_email",
  DARKWEB_CREDIT_CARD_LEAK_DARKOWL_ENTITY = "darkweb_credit_card_leak_darkowl_entity",
  PAID_ADS_GOOGLE_ADS_SERP = "paid_ads_google_ads_serp",
  PAID_ADS_FACEBOOK_ADS_APIFY = "paid_ads_facebook_ads_apify",
  PAID_ADS_BING_ADS_LIBRARY = "paid_ads_bing_ads_library",
  DARKMARKET_SHELLIX_LISTING_SEARCH = "darkmarket_shellix_listing_search",
}

export enum EnforcementUrlType {
  MALICIOUS = "malicious",
  COMPROMISED = "compromised",
  BUILDER = "builder",
  SHORTENER = "shortener",
}

export enum DomainsEnforcementType {
  PHISHING = "phishing",
  TRADEMARK = "trademark",
  COPYRIGHT = "copyright",
}

export enum AppsEnforcementType {
  DMCA = "dmca",
  TRADEMARK = "trademark",
}

export enum EnforcementType {
  DMCA = "dmca",
  TRADEMARK = "trademark",
  PHISHING = "phishing",
  COPYRIGHT = "copyright",
  COUNTERFEIT = "counterfeit",
  PRODUCT_MISUSE = "product_misuse",
  IMPERSONATION = "impersonation",
}

export enum NotificationType {
  PERIODIC_NOTIFICATION = "periodic_notification",
}

export enum NotificationPlatformType {
  EMAIL = "email",
  SLACK = "slack",
}

export enum NotificationFrequencyType {
  DAILY = "daily",
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  MONTHLY = "monthly",
}

export enum NotificationReportType {
  REPORT_STATUS_CHANGES = "report_status_changes",
}

export enum EnforcementMethod {
  MANUAL_FORM = "manual_form",
  MANUAL_EMAIL = "manual_email",
  AUTO_FORM = "auto_form",
  AUTO_EMAIL = "auto_email",
  API = "api",
  OTHER = "other",
}

export const EnforcementMethodLabels: { [key in EnforcementMethod]?: string } = {
  [EnforcementMethod.MANUAL_FORM]: "MANUAL",
  [EnforcementMethod.MANUAL_EMAIL]: "MANUAL",
  [EnforcementMethod.AUTO_FORM]: "AUTO_FORM",
  [EnforcementMethod.AUTO_EMAIL]: "AUTO_API",
  [EnforcementMethod.API]: "AUTO_API",
  [EnforcementMethod.OTHER]: "MANUAL",
};

export enum EnforcementMethodStage {
  INITIAL = "initial",
  ESCALATION = "escalation",
}

export enum EnforcementPlatformType {
  BUILDER = "builder",
  SHORTENER = "shortener",
  COUNTRY_CODE_TLD = "cc_tld",
  GENERIC_TLD = "generic_tld",
  REGISTRAR = "registrar",
  HOSTING_PROVIDER = "hosting_provider",
  WEBSITE_OWNER = "website_owner",
  UNKNOWN_TLD = "tld",
  CRYPTO_BLOCKER = "crypto_wallet",
  SITE_BLOCKER = "site_blocker",
  REGISTRY_TAKEDOWN_PROVIDER = "registry_takedown_provider",
}

export const EnforcementPlatformTypeLabels: { [key in EnforcementPlatformType]?: string } = {
  [EnforcementPlatformType.BUILDER]: "website builder",
  [EnforcementPlatformType.SHORTENER]: "URL shortener",
  [EnforcementPlatformType.COUNTRY_CODE_TLD]: "country code TLD",
  [EnforcementPlatformType.GENERIC_TLD]: "TLD registry",
  [EnforcementPlatformType.REGISTRAR]: "registrar",
  [EnforcementPlatformType.HOSTING_PROVIDER]: "hosting provider",
  [EnforcementPlatformType.WEBSITE_OWNER]: "website owner",
  [EnforcementPlatformType.UNKNOWN_TLD]: "TLD",
  [EnforcementPlatformType.CRYPTO_BLOCKER]: "crypto blocker",
  [EnforcementPlatformType.SITE_BLOCKER]: "site blocker",
  [EnforcementPlatformType.REGISTRY_TAKEDOWN_PROVIDER]: "registry takedown provider",
};

export enum AppEnforcementPlatformType {
  LEGITIMATE = "legitimate",
  THIRD_PARTY_APK = "third_party_apk",
}

export const AppEnforcementPlatformTypeLabels: { [key in AppEnforcementPlatformType]?: string } = {
  [AppEnforcementPlatformType.LEGITIMATE]: "legitimate",
  [AppEnforcementPlatformType.THIRD_PARTY_APK]: "third party APK",
};

export enum EnforcementPlatformSource {
  REGISTRAR_NAME = "report registrar name",
  HOSTING_PROVIDER = "report hosting provider",
  FLAGGED_URL_TLD = "flagged url top level domain",
  FLAGGED_URL_ROOT = "flagged url root domain",
  FLAGGED_URL_HOST = "flagged url host domain",
  AUTO_STAGING = "auto staging",
  USER = "user",
}

export enum StatsQueryOptions {
  BRAND = "brand",
  SOURCE = "source",
  PRODUCT = "product",
  GROUP_BY = "group_by",
  DATE_RANGE = "date_range",
  TAG = "tag",
  UPLOADER = "uploader",
  PROVIDER = "provider",
  REGISTRAR = "registrar",
  TAGS = "tags",
  PLATFORMS = "platforms",
}

export enum StatsGraphNames {
  REPORTS_SURFACED = "reports_surfaced",
  MEDIAN_TAKEDOWN_TIME = "median_takedown_time",
}

export enum StagingDisabledReason {
  MISSING_ENFORCEMENT_TYPE = "Enforcement type is required",
  MISSING_PLATFORM = "Enforcement platform is required",
  MISSING_SCREENSHOT = "Manual enforcement requires a screenshot",
  MISSING_CUSTOM_PLATFORM_NAME = "Custom platform requires a name",
  MISSING_ENFORCEMENT_METHOD = "Enforcement method is required",
  REQUEST_ALREADY_EXISTS = "An enforcement request for the same platform and type already exists",
  MISSING_PLATFORM_TYPE = "Enforcement platform type is required",
  MISSING_TRADEMARK = "Selecting trademark for trademark enforcements is required",
  MISSING_CONTENT_TYPE = "Selecting content type for social media enforcements is required",
}

export enum AIPromptType {
  REPORT_ACTION_SUGGESTION = "report_action_suggestion",
  ALERT_QUEUE_ACTION = "alert_queue_action",
  EMAILS_SENTIMENT_ANALYSIS = "emails_sentiment_analysis",
  ZEUS_BOT_CODE_ANALYSIS = "zeus_bot_code_analysis",
}

export enum AIPromptPlatform {
  DOMAIN = "domain",
  TWITTER = "twitter",
  SOCIAL_MEDIA = "social_media",
}

export enum AIPromptRole {
  USER = "user",
  SYSTEM = "system",
}

export enum AIPromptMetadataKey {
  ORG_PROMPT = "org_prompt",
  BASE_PROMPT = "base_prompt",
}

export enum ApiName {
  DARKOWL = "darkowl",
  DOMAIN_TOOLS_IRIS_INVESTIGATE = "domain_tools_iris_investigate",
}

export enum RateLimitInterval {
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  MONTH = "month",
}

export enum RateLimitProtocol {
  BREAK = "break",
  WAIT = "wait",
}

export enum ReportActions {
  REPORT = "REPORT",
  MONITOR = "MONITOR",
  ASK_FOR_CONFIRMATION = "ASK_FOR_CONFIRMATION",
  NO_ACTION = "NO_ACTION",
}

export const ReportActionsLabels: { [key in ReportActions]?: string } = {
  [ReportActions.REPORT]: "Report",
  [ReportActions.MONITOR]: "Move to Monitoring",
  [ReportActions.ASK_FOR_CONFIRMATION]: "Move to Needs Confirmation",
  [ReportActions.NO_ACTION]: "No action",
};

export enum SuggestedEnforcementDisabledReason {
  DEDUPE_TLD = "Enforcement already generated for TLD registry. (TLD can be either generic or country code, not both).",
  UNSUPPORTED_ENFORCEMENT_TYPES = "Platform does not support these enforcement types.",
  ACTIVE_ENFORCEMENT_ALREADY_EXISTS = "Active enforcement already exists for the platform(s).",
  UNSUPPORTED_TLD = "Flagged URL TLD is not a reportable TLD platform.",
  ALREADY_STAGED_IN_DOMAINS_TAKEDOWN_FORM = "Enforcement already staged in Takedown Form.",
  FLAGGED_URL_HAS_PATH = "Flagged url has a path.",
  ENFORCEMENT_TYPE_NOT_PHISHING = "Phishing not selected as an enforcement type in suggested enforcements form.",
  ENFORCEMENT_METADATA_NO_LEGAL_NAME = "Enforcement metadata does not have legal_name.",
  FLAGGED_URL_HAS_PLATFORM = "Flagged url's root or host is a platform domain.",
  DISABLED_HOST_OR_REGISTRAR = "CleanDNS enforcements are disabled for this host/registrar.",
  NOT_CRYPTO_CUSTOMER = "Customer type is not crypto.",
  URL_TYPE_NOT_MALICIOUS = "Url type selected in suggested enforcements form is not malicious.",
  IP_ADDRESS = "Flagged url contains IP address.",
  CRYPTO_WALLET_BLOCKING_NOT_SELECTED = "Crypto wallet blocking was unselected in Suggested Enforcements Form.",
  NOT_CLEANDNS_SUPPORTED = "Registrar and TLD not supported by CleanDNS.",
  MULTIPLE_ENFORCEMENTS_NOT_ALLOWED = "Cannot submit enforcement multiple times to the platform(s).",
  ALREADY_CREATED_CLEANDNS_ENFORCEMENT = "Already created CleanDNS enforcement for the platform type.",
  URL_TYPE_NOT_SUPPORTED = "Url type selected in suggested enforcements form is not supported for the platform(s).",
  ALREADY_STAGED_IN_SUGGESTED_ENFORCEMENTS = "Enforcement already staged in Suggested Enforcements Display.",
}

export enum SuggestedEnforcementDisabledAction {
  NO_ACTION = "No further action required.",
  BLOCK_AND_REREPORT = "Check status of existing request, or block and re-report",
  RESUBMIT_FORM_WITH_MALICIOUS = "If URL is malicious, re-submit suggested enforcements form",
  RESUBMIT_FORM_WITH_PHISHING = "If you want to auto-stage phishing enforcements, re-submit suggested enforcements form",
  RESUBMIT_FORM_WITH_CRYPTO_WALLET_BLOCKING = "If you want to auto-stage crypto wallet blocking enforcements, re-submit suggested enforcement form",
  ADD_ORG_LEGAL_NAME = "Add org's legal name in admin organizations page and re-submit suggested enforcements form.",
  REPORT_TO_FLAGGED_URL_HOST = "Review & submit phishing enforcement for host/platform of flagged url",
  REVIEW_HOST_REGISTRAR_ENFORCEMENT = "Review & submit phishing enforcement to corresponding host/registrar.",
  SWITCH_CUSTOMER_TYPE = "If you want to submit crypto wallet blocking enforcements, switch customer type in admin organizations page.",
  FOLLOWUP_ORIGINAL = "Please followup on the original request to the platform(s).",
  RESUBMIT_FORM_WITH_URL_TYPE = "If you think URL type is incorrect, re-submit suggested enforcements form",
  AVOID_DUPLICATION = "To avoid duplicate requests, please reject this enforcement or select another platform.",
}

export enum SuggestedEnforcementDisabledParamName {
  FLAGGED_URL_TLD = "flagged url tld",
  ALREADY_GENERATED_TLD_TYPE = "tld type already generated for",
  PLATFORM = "platform(s)",
  UNSUPPORTED_ENFORCEMENT_TYPES = "unsupported enforcement type(s)",
}

export enum PlatformSelectionMethod {
  MATCHED_STRING = "Matched String",
  UNKNOWN = "Unknown",
  CLEANED_REPORT_FIELD = "Cleaned Report Field",
  PLATFORM_KNOWN = "Platform Known",
  CUSTOM = "Custom",
  PLATFORM_PICKER = "Platform Picker",
}

export enum MatchAttributionType {
  DETECTION_ASSET = "detection_asset",
  SOURCING_QUERY = "sourcing_query",
  IR_TO_DR_RULE = "ir_to_dr_rule",
  DETECTION_RULE = "detection_rule",
}

export enum TagActionType {
  ADD = "add",
  REMOVE = "remove",
}

export enum FileActionType {
  UPLOAD = "upload",
  DELETE = "delete",
}

export enum ScriptServiceType {
  REPORT_UNBUNDLING = "report_unbundling",
  BACKFILL_EXTERNAL_IDS = "backfill_external_ids",
  BACKFILL_FULL_URL_SCREENSHOTS = "backfill_full_url_screenshots",
  BACKFILL_SOCIAL_MEDIA_DATA_SCREENSHOTS = "backfill_social_media_data_screenshots",
  BACKFILL_REPORT_LEVEL_SCREENSHOTS = "backfill_report_level_screenshots",
  BACKFILL_MOBILE_APP_SCREENSHOTS = "backfill_mobile_app_screenshots",
  BACKFILL_SPOOF_STATUS = "backfill_spoof_status",
  BACKFILL_PLATFORM_SUBTYPES = "backfill_platform_subtypes",
  BACKFILL_ML_FEATURES_FOR_ANALYSIS = "backfill_features_for_pivoting_analysis",
  BATCH_RUN_ALERTS = "batch_run_alerts",
  BACKFILL_COUNTRY_CODES = "backfill_country_codes",
  MERGE_SOCIAL_MEDIA_DATA_ROWS = "merge_social_media_data_rows",
  BACKFILL_IS_CUSTOMER_SOURCED = "backfill_is_customer_sourced",
}

export enum AlertLinkType {
  HYPERLINK = "hyperlink",
  REDIRECT = "redirect",
  SAME_ROOT_DOMAIN = "same_root_domain",
  OTHER = "other",
  SAME_IDENTIFIER = "same_identifier",
  HTML_SIMILARITY = "html_similarity",
}

export enum AlertLinkRelationship {
  PARENT_CHILD = "parent_child",
  PEER_TO_PEER = "peer_to_peer",
  PARENT = "parent",
  CHILD = "child",
}

export enum ThreatType {
  DOMAIN = "domain",
  URL = "url",
  DOMAIN_AND_URL = "domain_and_url",
  PAYLOAD = "payload",
  LINK = "link",
  RESPONSE = "response",
}

export enum AttackType {
  CREDENTIAL_THEFT = "credential_theft",
  REDIRECT = "redirect",
  BRAND_INFRINGEMENT = "brand_infringement",
  TYPOSQUATTED_DOMAIN = "typosquatted_domain",
}

export enum CRUDActionType {
  GET = "get",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  UPSERT = "upsert",
}

export enum OrganizationStatus {
  ACTIVE = "active",
  EVALUATING = "evaluating",
  PITCHING = "pitching",
  TRIAL_ENDED = "trial_ended",
  INACTIVE = "inactive",
  TEST_ORG = "test_org",
}

export const OrganizationStatusLabels: { [key in OrganizationStatus]?: string } = {
  [OrganizationStatus.ACTIVE]: "active",
  [OrganizationStatus.EVALUATING]: "trial",
  [OrganizationStatus.PITCHING]: "demo",
  [OrganizationStatus.TRIAL_ENDED]: "trial_ended",
  [OrganizationStatus.INACTIVE]: "inactive",
  [OrganizationStatus.TEST_ORG]: "test_org",
};

export enum SourcingQueryStatus {
  PREVIEW = "preview",
  ACTIVE = "active",
  DISABLED = "disabled",
}

export enum DCPPriority {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export enum DCPExperiment {
  REPORT_CREATION_FILTER_MODEL = "report_creation_filter_model",
}

export enum GenerateSuggestedEnforcementsMode {
  SUGGESTED_ENFORCEMENT_FORMS = "suggested_enforcement_forms",
  SINGLE_ENFORCEMENT = "single_enforcement",
}

export enum SuggestedEnforcementStatus {
  SUGGESTED = "suggested",
  AUTO_STAGED = "auto_staged",
  REJECTED = "rejected",
  STAGED = "staged",
}

export enum SupportedMaterializedViews {
  MV_ALERTS_SURFACED_BY_HOUR = "mv_alerts_surfaced_by_hour",
  MV_ALERTS_SURFACED_BY_DAY = "mv_alerts_surfaced_by_day",
  MV_ALERTS_VERIFIED = "mv_alerts_verified",
  MV_ALERTS_TIMES_TO_ACTIONED = "mv_alerts_times_to_actioned",
}

export enum IOCType {
  IP_ADDRESS = "ip_address",
  SITE_TITLE = "site_title",
  GTAG = "gtag",
  FACEBOOK_TRACKING_PIXEL = "facebook_tracking_pixel",
  URL = "url",
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
  QR_CODE = "qr_code",
  FILE = "file",
}

export enum IOCStatus {
  VERIFIED = "verified",
  UNVERIFIED = "unverified",
  BENIGN = "benign",
}

export const IOCStatusLabels: { [key in IOCStatus]?: string } = {
  [IOCStatus.VERIFIED]: "Malicious",
  [IOCStatus.UNVERIFIED]: "Unverified",
  [IOCStatus.BENIGN]: "No Threat Detected",
};

export enum EmailSentimentAnalysisVerdict {
  LOW_CONFIDENCE = "low_confidence",
  MEDIUM_CONFIDENCE = "medium_confidence",
  HIGH_CONFIDENCE = "high_confidence",
}

export enum DetectionConfigType {
  SOURCING_QUERY = "sourcing_query",
  DETECTION_ASSET = "detection_asset",
}

export enum SocialMediaContentType {
  PROFILE = "profile",
  POST = "post",
  PAGE = "page",
  GROUP = "group",
  VIDEO = "video",
  GROUP_CHAT = "group_chat",
  PHOTO = "photo",
  CHANNEL = "channel",
}

export enum AlertHashingMethod {
  SSDEEP = "ssdeep",
}

export enum AlertResourceType {
  WEBSITE_HTML = "website_html",
}

export enum StatType {
  ALERTS_VERIFIED_TOP_REGISTRARS = "alerts_verified_top_registrars",
  ALERTS_VERIFIED_TOP_WEB_HOSTS = "alerts_verified_top_web_hosts",
  ALERTS_VERIFIED_TOP_COUNTRY_CODES = "alerts_verified_top_country_codes",
  ALERTS_VERIFIED_TOP_PLATFORMS = "alerts_verified_top_platforms",
  ALERTS_VERIFIED_TOTAL_CREATED = "alerts_verified_total_created",
  ALERTS_SURFACED_TOTAL_CREATED = "alerts_surfaced_total_created",
  ALERTS_SURFACED_BREAKDOWN_BY_TIME = "alerts_surfaced_breakdown_by_time",
  ALERTS_MEDIAN_RESOLUTION_TIME = "alerts_median_resolution_time",
  ALERTS_MEDIAN_MITIGATION_TIME = "alerts_median_mitigation_time",
  ALERTS_MEDIAN_RESOLUTION_BREAKDOWN_BY_TIME = "alerts_median_resolution_breakdown_by_time",
  ALERTS_MEDIAN_MITIGATION_BREAKDOWN_BY_TIME = "alerts_median_mitigation_breakdown_by_time",
  ALERTS_RESOLVED_TOTAL_CREATED = "alerts_resolved_total_created",
  ALERTS_MEDIAN_RESOLUTION_BREAKDOWN_BY_PLATFORM = "alerts_median_resolution_breakdown_by_platform",
  ALERTS_RESOLVED_TOP_PLATFORMS = "alerts_resolved_top_platforms",
}

export enum JiraIssueStatusDescription {
  PLATFORM_REPLY = "platform_reply",
  STALE_REQUEST = "stale_request",
  ANALYST_REPLY = "analyst_reply",
  RETRACTION_SENT = "retraction_sent",
}

export const JiraIssueStatusDescriptionLabels: { [key in JiraIssueStatusDescription]?: string } = {
  [JiraIssueStatusDescription.PLATFORM_REPLY]: "Platform Replies",
  [JiraIssueStatusDescription.STALE_REQUEST]: "Stale Requests",
  [JiraIssueStatusDescription.ANALYST_REPLY]: "Analyst Replies",
  [JiraIssueStatusDescription.RETRACTION_SENT]: "Retractions",
};

export enum FormFieldsOptions {
  CUSTOMER_LEGAL_NAME = "customer_legal_name",
  CUSTOMER_ENFORCEMENT_EMAIL = "customer_enforcement_email",
  ORIGINAL_SITE = "original_site",
  DOPPEL_MAILING_ADDRESS = "doppel_mailing_address",
  REPORTING_AGENT_NAME = "reporting_agent_name",
  TRADEMARK_HOLDER_NAME = "trademark_holder_name",
  TRADEMARK_HOLDER_ADDRESS = "trademark_holder_address",
  TRADEMARK_REGISTRATION_NUMBER = "trademark_registration_number",
  TRADEMARK_REGISTRATION_OFFICE = "trademark_registration_office",
  TRADEMARK_MARK = "trademark_mark",
  TRADEMARK_HOLDER_COUNTRY_CODE = "trademark_holder_country_code",
  TRADEMARK_HOLDER_COMPANY_NAME = "trademark_holder_company_name",
  TRADEMARK_GOODS_OR_SERVICES_CLASS = "trademark_goods_or_services_class",
  TRADEMARK_HOLDER_WEBSITE = "trademark_holder_website",
  TRADEMARK_REGISTRATION_CERTIFICATE_URL = "trademark_registration_certificate_url",
  FLAGGED_URL = "flagged_url",
  UNSANITIZED_URL = "unsanitized_url",
  MESSAGE_TEMPLATE = "message_template",
}

export enum SubmissionSource {
  MANUAL = "manual",
  SCRIPT = "script",
  API = "api",
  GIT_PR = "git_pr",
  AUTOMATED_FORM = "automated_form",
  AUTOMATED_EMAIL = "automated_email",
}

export enum RetrievalOptions {
  ENVIRONMENT_VARIABLE = "environment_variable",
  DB_ACCESS = "db_access",
}

export enum FormFieldsDBTables {
  SPOOFING_REPORTS = "spoofing_reports",
  PROTECTED_ASSETS = "protected_assets",
  TRADEMARKS = "trademarks",
  ENFORCEMENT_TEMPLATES = "enforcement_templates",
}

export enum SourcedUrlStatus {
  PENDING = "pending",
  PROCESSED = "processed",
  DEDUPLICATED = "deduplicated",
  CRASHED = "crashed",
}

export enum DnsDigStatus {
  NOERROR = "NOERROR",
  NXDOMAIN = "NXDOMAIN",
  TIMEOUT = "TIMEOUT",
  NO_NAMESERVERS = "NO NAMESERVERS",
  UNKNOWN = "UNKNOWN",
}

export enum RuleFieldType {
  TEXT = "text",
  NUMERIC = "numeric",
  NO_INPUT = "none",
}

export enum AlertQueueProductTypes {
  DOMAINS = "domains",
  SOCIAL_MEDIA = "social_media",
}

export enum AutoSourcedSocialMediaPlatforms {
  TWITTER = "twitter",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  YOUTUBE = "youtube",
  TIKTOK = "tiktok",
}

export enum UrlAuditStatus {
  NOT_SOURCED = "not_sourced",
  SOURCED_BUT_NO_ALERT = "sourced_but_no_alert",
  SOURCED_AND_ALERT_CREATED = "sourced_and_alert_created",
}

export enum AlertAgeStatus {
  NOT_AGED = "< 21 Days",
  AGED = "> 21 Days",
}

export enum EmailCorrespondenceType {
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

export enum UrlAuditStatusSourcing {
  NOT_SOURCED = "not_sourced",
  SOURCED = "sourced",
  SOURCED_LATE = "sourced_late",
}

export enum UrlAuditStatusAlertCreated {
  NOT_CREATED = "not_created",
  CREATED = "created",
}

export enum Platform {
  COINBASE = "coinbase",
  BINANCE = "binance",
  OPENSEA = "opensea",
  MINTABLE = "mintable",
  MAGICEDEN = "magiceden",
  OKX = "okx",
  RARIBLE = "rarible",
  CRYPTO_OTHER = "crypto_other",
  SHELLIX = "shellix",
  DARKMARKET_OTHER = "darkmarket_other",
  DARKWEB = "darkweb",
  CRED_LEAKS = "cred_leaks",
  CREDIT_CARD_LEAKS = "credit_card_leaks",
  API_LEAKS = "api_leaks",
  DOMAINS = "domains",
  GITBOOK = "gitbook",
  WEBFLOW = "webflow",
  SHOPEE = "shopee",
  ROBLOX = "roblox",
  AMAZON = "amazon",
  WALMART = "walmart",
  ETSY = "etsy",
  EBAY = "ebay",
  LAZADA = "lazada",
  Z2U = "z2u",
  TOKOPEDIA = "tokopedia",
  FACEBOOK_MARKETPLACE = "facebook_marketplace",
  ECOMMERCE_OTHER = "ecommerce_other",
  EMAIL = "email",
  APPLE_APP_STORE = "apple_app_store",
  APKGK = "apkgk",
  UPTODOWN = "uptodown",
  STEPRIMO = "steprimo",
  APPBRAIN = "appbrain",
  GOOGLE_PLAY_STORE = "google_play_store",
  APTOIDE = "aptoide",
  NINEAPPS = "9apps",
  APKCOMBO = "apkcombo",
  APKPURE = "apkpure",
  MOBILE_APPS_OTHER = "mobile_apps_other",
  GOOGLE_ADS = "google_ads",
  FACEBOOK_ADS = "facebook_ads",
  BING_ADS = "bing_ads",
  PAID_ADS_OTHER = "paid_ads_other",
  TWITTER = "twitter",
  TIKTOK = "tiktok",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  YOUTUBE = "youtube",
  TELEGRAM = "telegram",
  LINKEDIN = "linkedin",
  REDDIT = "reddit",
  GITHUB = "github",
  PINTEREST = "pinterest",
  DISCORD = "discord",
  WHATSAPP = "whatsapp",
  TRUTH_SOCIAL = "truth_social",
  FACEBOOK_PROFILE = "facebook_profile",
  SPOTIFY = "spotify",
  TWITCH = "twitch",
  THREADS = "threads",
  SNAPCHAT = "snapchat",
  WEIBO = "weibo",
  MEDIUM = "medium",
  LINKTREE = "linktree",
  TUMBLR = "tumblr",
  SOCIAL_MEDIA_OTHER = "social_media_other",
  TELCO = "telco",
  SUSPICIOUS_EMAILS = "suspicious_emails",
  CLOUDFLARE_PAGES = "cloudflare_pages",
}

export enum PlatformSubtype {
  COINBASE = "coinbase",
  BINANCE = "binance",
  OPENSEA = "opensea",
  MINTABLE = "mintable",
  MAGICEDEN = "magiceden",
  OKX = "okx",
  RARIBLE = "rarible",
  CRYPTO_OTHER = "crypto_other",
  SHELLIX = "shellix",
  DARKMARKET_OTHER = "darkmarket_other",
  DARKWEB = "darkweb",
  CRED_LEAKS = "cred_leaks",
  CREDIT_CARD_LEAKS = "credit_card_leaks",
  API_LEAKS = "api_leaks",
  DOMAINS = "domains",
  GITBOOK = "gitbook",
  WEBFLOW = "webflow",
  SHOPEE = "shopee",
  ROBLOX = "roblox",
  AMAZON = "amazon",
  WALMART = "walmart",
  ETSY = "etsy",
  EBAY = "ebay",
  LAZADA = "lazada",
  Z2U = "z2u",
  TOKOPEDIA = "tokopedia",
  FACEBOOK_MARKETPLACE = "facebook_marketplace",
  ECOMMERCE_OTHER = "ecommerce_other",
  EMAIL = "email",
  APPLE_APP_STORE = "apple_app_store",
  APKGK = "apkgk",
  UPTODOWN = "uptodown",
  STEPRIMO = "steprimo",
  APPBRAIN = "appbrain",
  GOOGLE_PLAY_STORE = "google_play_store",
  APTOIDE = "aptoide",
  NINEAPPS = "9apps",
  APKCOMBO = "apkcombo",
  APKPURE = "apkpure",
  MOBILE_APPS_OTHER = "mobile_apps_other",
  GOOGLE_ADS = "google_ads",
  FACEBOOK_ADS = "facebook_ads",
  BING_ADS = "bing_ads",
  PAID_ADS_OTHER = "paid_ads_other",
  TWITTER = "twitter",
  TWITTER_POST = "twitter_post",
  TIKTOK = "tiktok",
  TIKTOK_VIDEO = "tiktok_video",
  FACEBOOK = "facebook",
  FACEBOOK_POST = "facebook_post",
  FACEBOOK_GROUPS = "facebook_groups",
  FACEBOOK_PAGE = "facebook_page",
  INSTAGRAM = "instagram",
  INSTAGRAM_POST = "instagram_post",
  YOUTUBE = "youtube",
  TELEGRAM = "telegram",
  TELEGRAM_POST = "telegram_post",
  LINKEDIN = "linkedin",
  REDDIT = "reddit",
  GITHUB = "github",
  PINTEREST = "pinterest",
  DISCORD = "discord",
  WHATSAPP = "whatsapp",
  TRUTH_SOCIAL = "truth_social",
  FACEBOOK_PROFILE = "facebook_profile",
  SPOTIFY = "spotify",
  TWITCH = "twitch",
  THREADS = "threads",
  SNAPCHAT = "snapchat",
  WEIBO = "weibo",
  MEDIUM = "medium",
  LINKTREE = "linktree",
  TUMBLR = "tumblr",
  SOCIAL_MEDIA_OTHER = "social_media_other",
  TELCO = "telco",
  SUSPICIOUS_EMAILS = "suspicious_emails",
  CLOUDFLARE_PAGES = "cloudflare_pages",
}

export enum PlatformSubtypeId {
  COINBASE = "162f925c-fe0c-4374-a92e-6edbf41d408c",
  BINANCE = "8a8a53ca-7380-43e5-9ba2-c759083a9c09",
  OPENSEA = "3ed1a2b6-4992-4b10-b47d-54b5a00c9fe6",
  MINTABLE = "aa812de4-4e3d-4fb4-ab8d-d3e48f7ada53",
  MAGICEDEN = "8f90f2d6-a3d3-4c5c-9493-73a3c4abaad2",
  OKX = "b68fb590-7a51-44ee-bef9-a6ee316ee998",
  RARIBLE = "84ae12b3-b2e0-4e47-a51a-dd9349ecccff",
  CRYPTO_OTHER = "4f1cf31d-dd44-4cdb-92a6-59cd0fc0d29d",
  SHELLIX = "07cf95d2-3f7e-454e-9511-582cae22864e",
  DARKMARKET_OTHER = "fbebc352-71f6-46e2-91cc-7dbab6c415b4",
  DARKWEB = "f63713b6-4b98-41d5-8b2b-d50700cd214c",
  CRED_LEAKS = "f41fbadc-7871-489b-b31a-8b1a3930616e",
  CREDIT_CARD_LEAKS = "38b4dec9-e23b-4587-9c5d-f2ccb89c209c",
  API_LEAKS = "d876ab72-ab37-43aa-80d0-b087a6e67dc5",
  DOMAINS = "68a517dd-9345-4138-888a-ccef309847df",
  GITBOOK = "dd265ffd-8812-4fdd-aac0-17275f49d37d",
  WEBFLOW = "c292648e-3cec-4239-9c42-82f007e6a83e",
  SHOPEE = "6d0382af-7572-4cc9-8983-baff7715ad65",
  ROBLOX = "14832a79-57cb-4489-8511-6302ab063b36",
  AMAZON = "daf0662a-4927-4bd5-9cac-ae4a95de2157",
  WALMART = "86cb41ad-d7ab-4fba-b3d1-55388099dcc0",
  ETSY = "dec13253-0f21-429e-aec1-ef3549ab7cad",
  EBAY = "8962b067-4b16-4133-bbf6-28b11b6800ca",
  LAZADA = "3365fc81-a070-4a65-8636-7bda440a979c",
  Z2U = "206265a6-102e-447f-8264-4b0688f38095",
  TOKOPEDIA = "eb5465c0-094f-44e2-92ab-d04d1f17f023",
  FACEBOOK_MARKETPLACE = "0ddbfbe5-ca35-4823-9c06-00cd3dc2e382",
  ECOMMERCE_OTHER = "575a839f-fb3f-4966-9518-f7679af3bc24",
  EMAIL = "10aa66d0-f5a3-4d22-9874-d60edfb4c9dc",
  APPLE_APP_STORE = "4a21c36e-5487-46fc-8477-d68aa303e279",
  APKGK = "1c012f7f-0b3d-45d7-ac85-0f582bf20116",
  UPTODOWN = "324ac9e3-83de-46ed-9b65-692dc3b2625b",
  STEPRIMO = "066d6868-5c64-4a8d-ad7b-10a6535ac41d",
  APPBRAIN = "eb631b90-8fac-4660-b272-ed9799ec7d57",
  GOOGLE_PLAY_STORE = "dd826a38-9ee1-4a48-971e-aacbbfe26735",
  APTOIDE = "d128e43a-11c7-4304-b7c4-359a62bfd4ac",
  NINEAPPS = "822bafe4-cc2b-4599-94ab-dfd208ce3659",
  APKCOMBO = "d5349cd4-2fff-44e2-9321-07845ffac7e7",
  APKPURE = "220568ac-6c30-478f-a97b-b0d7ba95d331",
  MOBILE_APPS_OTHER = "d8848696-8a02-4e32-8fc8-3e4c01c136c0",
  GOOGLE_ADS = "b049dff7-9075-4eb2-8d19-5c7bf18c4195",
  FACEBOOK_ADS = "e0c8b792-43c2-41f3-ba85-c8ecf7b63ea8",
  BING_ADS = "e53c115f-0afc-4bfe-927f-7c0b217c7afa",
  PAID_ADS_OTHER = "f81ff234-0f97-49a6-bf04-a785e023c190",
  TWITTER = "1422c0b9-ea0a-43bf-9f68-799015afe642",
  TWITTER_POST = "ce2ae0dd-942c-4cf3-8155-a9c684ca527b",
  TIKTOK = "d52b361e-94a9-4ee7-8cf9-c1aaf5cd4c9d",
  TIKTOK_VIDEO = "c703fd68-e25c-4163-8357-430c446db5e3",
  FACEBOOK = "5d03c276-448e-4036-b1a3-35be25ee5e35",
  FACEBOOK_POST = "5b0082e1-bde2-4744-a604-436cb428121c",
  FACEBOOK_GROUPS = "58e518d9-2ce3-4f9d-813c-baa1be1e842e",
  FACEBOOK_PAGE = "f930ffe7-6006-42e2-b0ba-af8d71dee338",
  INSTAGRAM = "19ac9b4e-fb9c-4350-b5f3-f55347809457",
  INSTAGRAM_POST = "d93a1fb3-2fae-425e-893c-a450238a52f4",
  YOUTUBE = "c751b205-50ee-4358-ae6b-4af48483bcec",
  TELEGRAM = "fcb65ff5-06d2-4e5b-9b9f-63dc2d64579a",
  TELEGRAM_POST = "28d64ef6-074f-42c0-9db4-3974961ee8e5",
  LINKEDIN = "7913ed24-b421-4e57-a986-52c722944daa",
  REDDIT = "a3e5997d-2eb9-407f-82e7-e8bfc07c343a",
  GITHUB = "8a559b31-7dfb-49f9-af77-4b720076efec",
  PINTEREST = "a37ddc33-874b-43e3-9150-e85072e6938c",
  DISCORD = "309d4fd7-0cf7-43f4-9edf-54f32fa49298",
  WHATSAPP = "f294fc77-8968-45c1-877d-7fdfdadc54f5",
  TRUTH_SOCIAL = "a7c57162-4437-4cc8-ad7c-314782238903",
  FACEBOOK_PROFILE = "752ba41a-a929-43d7-b765-1b623245bf7f",
  SPOTIFY = "bd1b4a58-acbb-4f33-9b77-557fd4de0488",
  TWITCH = "3d7e7c4e-d4f2-4f80-a7e6-a6c0a8ca12ac",
  THREADS = "1f013ba5-2e44-45d5-878c-c7ad34b80022",
  SNAPCHAT = "da3dd4cc-9eac-4ddc-a413-f5f9b212d3fe",
  WEIBO = "05a3e31b-1acb-430f-827f-6b81b8902a7d",
  MEDIUM = "b8268c27-245f-493b-bfef-6eeb6a07d9cb",
  LINKTREE = "7fbb3170-ead8-4734-b06b-7b1a73f62b22",
  TUMBLR = "ae54ec20-3061-41f5-9a23-b41d5ba6de96",
  SOCIAL_MEDIA_OTHER = "1b626269-cb0a-40be-81a9-74c1cba35890",
  TELCO = "103ce2aa-4623-4a80-ac08-205079e80ded",
  SUSPICIOUS_EMAILS = "8294af10-c854-4c4f-a066-3282655745cd",
  CLOUDFLARE_PAGES = "aecbae92-e822-4063-993c-b6bff665cda9",
}

export enum ProductType {
  DOMAIN = "domains",
  URL = "urls",
  SOCIAL_MEDIA = "social_media",
  NFT = "nft",
  CRYPTO = "crypto",
  MOBILE_APP = "mobile_apps",
  PAID_AD = "paid_ads",
  ECOMMERCE = "ecommerce",
  EMAIL = "email",
  DARKWEB = "darkweb",
  DARK_MARKET = "darkmarket",
  TELCO = "telco",
  SUSPICIOUS_EMAILS = "suspicious_emails",
  METAVERSE = "metaverse",
  CODE_REPOS = "code_repos",
  IGNORED = "ignored",
}

export enum Archetype {
  DOMAIN = "domains",
  SOCIAL_MEDIA = "social_media",
  SOCIAL_MEDIA_POST = "social_media_post",
  SOCIAL_MEDIA_GROUP = "social_media_group",
  CRYPTO = "crypto",
  MOBILE_APP = "mobile_apps",
  PAID_AD = "paid_ads",
  ECOMMERCE = "ecommerce",
  EMAIL = "email",
  DARKWEB = "darkweb",
  CRED_LEAKS = "cred_leaks",
  CREDIT_CARD_LEAKS = "credit_card_leaks",
  API_LEAKS = "api_leaks",
  DARK_MARKET = "darkmarket",
  TELCO = "telco",
  SUSPICIOUS_EMAILS = "suspicious_emails",
}

