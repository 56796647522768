import {
  DISNEY_ORG_ID,
  OrganizationType,
  YUGA_ORG_ID,
  OPENSEA_ORG_ID,
  DISNEY_OLD_2_ORG_ID,
  ReportType,
  MYSTEN_LABS_ID,
  META_ORG_ID,
  STARBUCKS_ORG_ID,
  ART_BLOCKS_ORG_ID,
  UNISWAP_ORG_ID,
  NOTION_ORG_ID,
} from './constants'

const DEFAULT_DATE_FILTER_ORGS = new Set<string>([OPENSEA_ORG_ID, YUGA_ORG_ID])
// TODO [DOP-2708]: after we find a long-term solution for http vs https this shouldn't be necessary
const DEFAULT_TO_HTTP_ORGS = new Set<string>(['9f0494c7-6d5f-4c2a-950a-35aff89c3e5c'])

export function isBulkActionsEnabled(selectedReportType: ReportType): boolean {
  return selectedReportType !== ReportType.SUSPICIOUS_EMAILS
}

export function isMarketplaceMatchCountEnabled(orgId): boolean {
  return orgId == DISNEY_ORG_ID
}

export function isReportFormEnabled(
  orgType: OrganizationType,
  selectedReportType: ReportType,
): boolean {
  return (
    orgType != OrganizationType.MARKETPLACE && selectedReportType != ReportType.DARK_WEB
  )
}

export function isReportedTabEnabled(orgType: OrganizationType): boolean {
  return orgType != OrganizationType.MARKETPLACE
}

export function isDefaultDateFilterEnabled(orgId: string): boolean {
  return DEFAULT_DATE_FILTER_ORGS.has(orgId)
}

export function isDisneyOrgId(orgId: string): boolean {
  return orgId === DISNEY_ORG_ID || orgId === DISNEY_OLD_2_ORG_ID
}

export function isExternalSourceEnabled(orgId: string): boolean {
  return orgId === MYSTEN_LABS_ID
}

export function isNftVolumeEnabled(orgId: string): boolean {
  return [META_ORG_ID, YUGA_ORG_ID, ART_BLOCKS_ORG_ID, UNISWAP_ORG_ID].includes(orgId)
}

export function isEstimatedValueEnabled(orgId: string): boolean {
  return [YUGA_ORG_ID, META_ORG_ID, STARBUCKS_ORG_ID].includes(orgId)
}

export function shouldDefangLinks(orgId: string): boolean {
  return [MYSTEN_LABS_ID, NOTION_ORG_ID].includes(orgId)
}

export function shouldDefaultToHttp(orgId: string): boolean {
  return DEFAULT_TO_HTTP_ORGS.has(orgId)
}
