import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
  Tooltip,
  Fade,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import { FiMenu } from 'react-icons/fi'
import LogoutButton from './logout_button'
import {
  DOPPEL_CLICKABLE_GREY,
  DOPPEL_DARK,
  DOPPEL_DARK_SECONDARY,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '../utils/style'
import { DOPPEL_LOGO_URL } from '@/utils/image'

export type SidebarItem = {
  name: string
  icon: IconType
  href: string
  isVisible: boolean
  isInternal: boolean
}

const getSidebarWidth = (webSidebarOpen: boolean) => {
  return webSidebarOpen ? '250px' : '120px'
}

const getSidebarItemBackgroundColor = (index: number, currentPage: number) => {
  return index == currentPage ? DOPPEL_CLICKABLE_GREY : 'transparent'
}

const getSidebarForegroundColor = (
  index: number,
  currentPage: number,
  isInternal: boolean,
) => {
  if (isInternal) {
    return DOPPEL_INTERNAL_PURPLE
  }
  return index == currentPage ? DOPPEL_DARK : DOPPEL_WHITE
}

const renderSidebarItem = (
  sidebarItem: SidebarItem,
  webSidebarOpen: boolean,
  index: number,
  currentPage: number,
  setCurrentPage: (currentPageNumber: number) => void,
): any => {
  if (!sidebarItem.isVisible) return null

  return (
    <NavItem
      backgroundColor={getSidebarItemBackgroundColor(index, currentPage)}
      color={getSidebarForegroundColor(index, currentPage, sidebarItem.isInternal)}
      href={sidebarItem.href}
      icon={sidebarItem.icon}
      key={sidebarItem.name}
      name={sidebarItem.name}
      onClick={() => setCurrentPage(index)}
      overflow="hidden"
      transition="width"
      transitionDuration="0.4s"
      webSidebarOpen={webSidebarOpen}
      width={webSidebarOpen ? '100%' : 50}
    >
      {webSidebarOpen && (
        <Text
          fontSize="small"
          marginLeft="12px"
          noOfLines={1}
          opacity={webSidebarOpen ? 1 : 0}
          transition="opacity"
          transitionDuration="0.3s"
          wordBreak={'break-all'}
        >
          {sidebarItem.name}
        </Text>
      )}
    </NavItem>
  )
}

export default function Sidebar({ sidebarItems, component }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [webSidebarOpen, setWebSidebarOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const router = useRouter()

  useEffect(() => {
    // TODO (DOP-4556) make this dynamic with sidebarItems

    // Order is important here (do not change): '/admin/' needs to be before 'organization'
    if (router.pathname.includes('/admin/')) {
      const adminIndex = sidebarItems.findIndex((item) => item.name === 'Admin')
      setCurrentPage(adminIndex)
    } else if (
      router.pathname.includes('collections') ||
      router.pathname.includes('brands')
    ) {
      setCurrentPage(1)
    } else if (router.pathname.includes('analytics')) {
      setCurrentPage(2)
    } else if (
      router.pathname.includes('users') ||
      router.pathname.includes('notification_settings') ||
      router.pathname.includes('tags')
    ) {
      const organizationIndex = sidebarItems.findIndex(
        (item) => item.name === 'Organization',
      )
      setCurrentPage(organizationIndex)
    } else if (router.pathname.includes('app-settings')) {
      const appSettingsIndex = sidebarItems.findIndex(
        (item) => item.name === 'App Settings',
      )
      setCurrentPage(appSettingsIndex)
    } else if (router.pathname.includes('suspicious-emails')) {
      const suspiciousEmailsIndex = sidebarItems.findIndex(
        (item) => item.name === 'Suspicious Emails',
      )
      setCurrentPage(suspiciousEmailsIndex)
    }
  }, [router.pathname])

  const closeSidebarOnPageSelection = (page: number) => {
    if (page != currentPage) {
      setCurrentPage(page)
    }
  }

  return (
    <Box minH="100vh">
      <SidebarContent
        currentPage={currentPage}
        display={{ base: 'none', md: 'block' }}
        onClose={() => onClose}
        setCurrentPage={closeSidebarOnPageSelection}
        setWebSidebarOpen={setWebSidebarOpen}
        sidebarItems={sidebarItems}
        transition="width"
        transitionDuration="0.5s"
        webSidebarOpen={webSidebarOpen}
      />

      {/* mobiledrawer */}

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        onOverlayClick={onClose}
        placement="left"
        returnFocusOnClose={false}
        size="full"
      >
        <DrawerOverlay />

        <DrawerContent>
          <SidebarContent
            currentPage={currentPage}
            onClose={onClose}
            setCurrentPage={closeSidebarOnPageSelection}
            sidebarItems={sidebarItems}
            webSidebarOpen={true}
          />
        </DrawerContent>
      </Drawer>

      <VStack spacing={{ base: 2, md: 0 }} width="100%">
        {/* mobilenav */}

        <MobileNav
          display={{ base: 'flex', md: 'none' }}
          onOpen={onOpen}
          width="100%"
        />

        {/* content */}

        <Box
          paddingLeft={{ base: '0', md: getSidebarWidth(webSidebarOpen) }}
          transition="padding"
          transitionDuration="0.5s"
          width="100%"
        >
          {component}
        </Box>
      </VStack>
    </Box>
  )
}

type SidebarProps = {
  onClose: () => void
  sidebarItems: SidebarItem[]
  setCurrentPage: (currentPageNumber: number) => void
  currentPage: number
  webSidebarOpen?: boolean
  setWebSidebarOpen?: (open: boolean) => void
} & BoxProps

const SidebarContent = ({
  onClose,
  sidebarItems,
  setCurrentPage,
  currentPage,
  webSidebarOpen,
  setWebSidebarOpen,
  ...rest
}: SidebarProps) => {
  const webWidth = getSidebarWidth(webSidebarOpen)

  return (
    <Box
      bg={DOPPEL_DARK_SECONDARY}
      border={0}
      h="full"
      pos="fixed"
      w={{ base: 'full', md: webWidth }}
      zIndex={3}
      {...rest}
    >
      <VStack alignItems="left" height="100%" mt="2" mx="8">
        <Flex alignItems="center" h="20" mt="2">
          <Box>
            <IconButton
              aria-label={'Menu'}
              bg={DOPPEL_DARK_SECONDARY}
              display={{ base: 'none', md: 'flex' }}
              icon={<FiMenu />}
              onClick={() => setWebSidebarOpen(!webSidebarOpen)}
            />
          </Box>

          {webSidebarOpen && (
            <Fade in={webSidebarOpen}>
              <Text fontSize="2xl" fontWeight="bold" marginLeft="12px">
                Doppel
              </Text>

              <Spacer />

              <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Fade>
          )}
        </Flex>

        {sidebarItems.map((sidebarItem, index) =>
          renderSidebarItem(
            sidebarItem,
            webSidebarOpen,
            index,
            currentPage,
            setCurrentPage,
          ),
        )}

        <Spacer />

        <LogoutButton
          buttonSizeState={'normal'}
          mb="8"
          opacity={webSidebarOpen ? 1 : 0}
          width="100%"
        />
      </VStack>
    </Box>
  )
}

type NavItemProps = {
  href: string
  icon: IconType
  children?: ReactNode
  name: string
  webSidebarOpen: boolean
} & FlexProps
const NavItem = ({
  href,
  icon,
  children,
  name,
  webSidebarOpen,
  ...rest
}: NavItemProps) => {
  return (
    <NextLink
      href={href}
      passHref
      style={{ textDecoration: 'none', boxShadow: 'none' }}
    >
      <Link>
        <Tooltip isDisabled={webSidebarOpen} label={name} placement="right">
          <Flex
            _hover={{
              bg: 'gray.200',
              color: DOPPEL_DARK,
            }}
            align="center"
            borderRadius="lg"
            cursor="pointer"
            p="4"
            role="group"
            {...rest}
          >
            {icon && <Icon as={icon} fontSize="16" marginY="0.5" />}

            {children}
          </Flex>
        </Tooltip>
      </Link>
    </NextLink>
  )
}

type MobileProps = {
  onOpen: () => void
} & FlexProps
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      alignItems="center"
      bg={useColorModeValue(DOPPEL_WHITE, DOPPEL_DARK_SECONDARY)}
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      borderBottomWidth="1px"
      height="20"
      justifyContent="flex-start"
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      {...rest}
    >
      <IconButton
        aria-label="open menu"
        icon={<FiMenu />}
        marginRight="12px"
        onClick={onOpen}
        variant="outline"
      />

      <Box>
        <Image alt="" height="25px" src={DOPPEL_LOGO_URL} />
      </Box>

      <Text fontSize="2xl" fontWeight="bold" ml="12px">
        Doppel
      </Text>

      <Spacer />

      <LogoutButton buttonSizeState={'normal'} />
    </Flex>
  )
}
