import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { DOPPEL_ERROR, DOPPEL_TERMINAL_BLUE, DOPPEL_WHITE } from '../../utils/style'

type DoppelModalProps = {
  isOpen: boolean
  onClose: () => void
  title: string
  message?: string
  body?: React.ReactNode
  primaryAction: () => void
  primaryActionDisabled?: boolean
  primaryLabel: string
  size?: string
  isDanger?: boolean
}

const DoppelModal: React.FC<DoppelModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
  body,
  primaryAction,
  primaryActionDisabled,
  primaryLabel,
  size,
  isDanger = false,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {message}

          {body}
        </ModalBody>

        <ModalFooter>
          <Button mr={2} onClick={onClose}>
            Cancel
          </Button>

          <Button
            bg={isDanger ? DOPPEL_ERROR : DOPPEL_TERMINAL_BLUE}
            isDisabled={primaryActionDisabled}
            mr={3}
            onClick={primaryAction}
            textColor={DOPPEL_WHITE}
          >
            {primaryLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DoppelModal
