import { Flex, Icon, Heading, Button, Text } from '@chakra-ui/react'
import { MdError } from 'react-icons/md'

export default function ErrorPage() {
  return (
    <Flex align="center" direction="column" h="100vh" justify="center" w="100%">
      <Icon as={MdError} boxSize={16} />

      <Heading as="h1" mt={2} size="lg">
        Something went wrong
      </Heading>

      <Text mt={2} textAlign="center">
        There was a problem connecting to Doppel Vision.
        <br />
        Please try refreshing the page, or contact us if the problem persists.
      </Text>

      <Button mt={4} onClick={() => window.location.reload()} size="lg">
        Refresh
      </Button>
    </Flex>
  )
}
