import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Flex,
  Spacer,
  Text,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  DOPPEL_WHITE,
  DOPPEL_TEXT_WHITE,
  DOPPEL_DARK,
  DOPPEL_BUTTON_GREY,
  DOPPEL_TEXT_BLUE,
  DOPPEL_TEXT_RED,
  DOPPEL_DIVIDER,
  DOPPEL_TEXT_GREY,
  DOPPEL_GREY,
  DOPPEL_TEXT_INTERNAL_PURPLE_DARK,
  FONT_SIZE_SMALL,
  FONT_SIZE_MEDIUM,
} from '@/utils/style'
import { MdCheck } from 'react-icons/md'
import React, { useState, ReactNode } from 'react'
import DoppelModal from '../shared/doppel_modal'

export type DropdownItem = {
  label: ReactNode
  value: string
  topText?: string
  isInternal?: boolean
  isDanger?: boolean
  confirmationModal?: {
    title: string
    message: string
    primaryLabel: string
  }
}

const getItemTextColor = (item: DropdownItem, selectedValue: string): string => {
  if (item.value === selectedValue) return DOPPEL_TEXT_BLUE
  if (item.isDanger) return DOPPEL_TEXT_RED
  if (item.isInternal) return DOPPEL_TEXT_INTERNAL_PURPLE_DARK
  return 'none'
}

type DropdownProps = {
  // one of items or itemGroups is required
  items?: DropdownItem[]
  groupedItems?: DropdownItem[][]
  onSelect: (value: string) => void
  selectedValue?: string
  menuLabel?: string
  icon?: React.ReactNode
  customMenuButton?: React.ReactNode
}

const DROPDOWN_BG_COLOR = DOPPEL_WHITE
const DROPDOWN_HOVER_COLOR = DOPPEL_GREY

export const Dropdown: React.FC<DropdownProps> = ({
  items,
  groupedItems,
  onSelect,
  selectedValue,
  menuLabel,
  icon,
  customMenuButton,
}) => {
  const [confirmationModalItem, setConfirmationModalItem] = useState(null)

  const flatItemList = groupedItems ? [...groupedItems.flat()] : [...items]
  const selectedItem = flatItemList.find((item) => item.value === selectedValue)

  const onItemClick = (item) => {
    item.confirmationModal ? setConfirmationModalItem(item) : onSelect(item.value)
  }

  const renderItem = (item: DropdownItem) => {
    return (
      <MenuItem
        _hover={{ bg: DROPDOWN_HOVER_COLOR }}
        borderRadius="md"
        fontWeight={500}
        key={item.value}
        onClick={() => onItemClick(item)}
        textColor={getItemTextColor(item, selectedValue)}
      >
        <Flex flexDir={'column'} w="full">
          {item.topText && (
            <Text
              color={DOPPEL_TEXT_GREY}
              fontSize={FONT_SIZE_SMALL}
              fontWeight={400}
              mb={-1}
            >
              {item.topText}
            </Text>
          )}

          <Flex alignItems={'center'} flex={1} fontSize={FONT_SIZE_MEDIUM}>
            {item.label}

            <Spacer pr={2} />

            {item.value === selectedValue && <MdCheck />}
          </Flex>
        </Flex>
      </MenuItem>
    )
  }

  return (
    <Menu>
      {customMenuButton || (
        <MenuButton
          as={Button}
          bg={DOPPEL_BUTTON_GREY}
          display="flex"
          fontSize={FONT_SIZE_MEDIUM}
          height={9}
          pl={3}
          pr={2}
          rightIcon={<ChevronDownIcon h={5} w={5} />}
          textColor={DOPPEL_TEXT_WHITE}
        >
          <Flex alignItems="center">
            {icon && (
              <>
                {icon}

                <Spacer mx={1} />
              </>
            )}

            {menuLabel || selectedItem?.label}
          </Flex>
        </MenuButton>
      )}

      <MenuList
        bg={DROPDOWN_BG_COLOR}
        maxHeight="500px"
        overflow="auto"
        px={1}
        textColor={DOPPEL_DARK}
      >
        {groupedItems
          ? groupedItems
              .filter((group) => group.length)
              .map((group, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <MenuDivider borderColor={DOPPEL_DIVIDER} />}

                  <MenuGroup>{group.map(renderItem)}</MenuGroup>
                </React.Fragment>
              ))
          : items.map(renderItem)}
      </MenuList>

      {confirmationModalItem && (
        <DoppelModal
          isDanger
          isOpen={confirmationModalItem}
          message={confirmationModalItem.confirmationModal.message}
          onClose={() => setConfirmationModalItem(null)}
          primaryAction={() => {
            onSelect(confirmationModalItem.value)
            setConfirmationModalItem(null)
          }}
          primaryLabel={confirmationModalItem.confirmationModal.primaryLabel}
          title={confirmationModalItem.confirmationModal.title}
        />
      )}
    </Menu>
  )
}
