import { PlatformSubtype } from '@/generated/enums'
import { Spoofing_Reports_Bool_Exp } from '@/generated/graphql'

// Define a mapping configuration for Lucene fields to GraphQL conditions
const LUCENE_TO_GRAPHQL: {
  [key: string]: (value: string) => Spoofing_Reports_Bool_Exp
} = {
  subject: (value: string) => _emailILikeWhereClause('subject', value),
  body: (value: string) => _emailILikeWhereClause('body', value),
  sender: (value: string) => _emailILikeWhereClause('sender', value),
  receiver: (value: string) => _emailILikeWhereClause('receiver', value),
  // Add more field mappings here as needed
}

/**
 * Parses a Lucene query string into a GraphQL where clause. The query string
 * should be in the format of "field1:value1 field2:value2" or
 * "field1:(value1 OR value2)". The following field mappings are supported:
 * - subject: _ilike
 * - body: _ilike
 * - sender: _ilike
 * - receiver: _ilike
 *
 * The following Lucene features are not supported yet:
 * - Wildcards (*, ?)
 * - Fuzzy searches (~)
 * - Proximity searches (~)
 * - Range searches ([start TO end])
 * - Boosting (^)
 * - Grouping (())
 * - NOT, AND, OR operators
 */
export function parseLuceneToGraphQL(query: string): Spoofing_Reports_Bool_Exp[] {
  // Initialize the GraphQL where clause with an _and array
  const whereClauses: Spoofing_Reports_Bool_Exp[] = []

  // Regular expression to match field:value pairs in the query
  const conditions = query.match(/\b(\w+):("[^"]+"|\S+)/g)

  if (conditions) {
    conditions.forEach((condition) => {
      // Split each condition into field and value
      const [field, valueWithQuotes] = condition.split(':')
      const value = valueWithQuotes.replace(/"/g, '') // Remove quotes from the value if they exist

      if (field in LUCENE_TO_GRAPHQL) {
        whereClauses.push(LUCENE_TO_GRAPHQL[field](value))
      }
    })
  }
  return whereClauses
}

export function getEmailWhereClause(query: string): Spoofing_Reports_Bool_Exp[] {
  const emailSubtype = {
    platform_subtype: { name: { _eq: PlatformSubtype.SUSPICIOUS_EMAILS } },
  }
  let clauses: Spoofing_Reports_Bool_Exp[] = [emailSubtype]
  if (!query) {
    return clauses
  }
  const luceneClauses = parseLuceneToGraphQL(query)
  if (luceneClauses && luceneClauses.length > 0) {
    clauses = clauses.concat(luceneClauses)
  } else {
    // If there's no Lucene query, search across all possible email text fields
    clauses.push({
      _or: ['subject', 'body', 'sender', 'receiver'].map((field) =>
        _emailILikeWhereClause(field, query),
      ),
    })
  }
  return clauses
}

/**
 * Creates a GraphQL condition for searching a single field in the email table
 * with a given value.
 *
 * @param {string} field - The name of the field to search.
 * @param {string} value - The value to search for.
 *
 * @returns {GraphQLCondition} - A GraphQL condition representing the search.
 */
function _emailILikeWhereClause(
  field: string,
  value: string,
): Spoofing_Reports_Bool_Exp {
  return { spoof_matches: { email: { [field]: { _ilike: `%${value}%` } } } }
}
