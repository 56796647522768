import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useMemo, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { useIsEmployee, useShouldApplyCustomerPermissions } from './id_token_claims'

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading } = useAuth0()
  const isEmployee = useIsEmployee()
  const [shouldApplyCustomerPermissions] = useShouldApplyCustomerPermissions()
  const apolloClientInstanceCache = useRef<
    Record<string, ApolloClient<NormalizedCacheObject>>
  >({})

  const apolloClient = useMemo(() => {
    const shouldGrantAdminPermissions = !shouldApplyCustomerPermissions && isEmployee

    const cacheKey = `${shouldGrantAdminPermissions}`

    if (apolloClientInstanceCache.current[cacheKey]) {
      return apolloClientInstanceCache.current[cacheKey]
    }

    if (isLoading || !getAccessTokenSilently) return null

    const client = createApolloClient(
      computeApolloLink(getAccessTokenSilently, shouldGrantAdminPermissions),
    )

    apolloClientInstanceCache.current[cacheKey] = client

    return client
  }, [isLoading, getAccessTokenSilently, shouldApplyCustomerPermissions, isEmployee])

  if (!apolloClient) {
    return <></>
  }
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

const createApolloClient = (
  apolloLink: ApolloLink,
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    // connectToDevTools: true, enable to see in console in prod; can see in devtools by for localhost
    link: apolloLink,
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        if (
          !defaultDataIdFromObject(responseObject) &&
          !(
            responseObject.__typename.endsWith('aggregate') ||
            responseObject.__typename.endsWith('aggregate_fields')
          )
        ) {
          // Check for types that require a custom cache key
          // console.warn(`Type ${responseObject.__typename} doesn't have an \`id\` or \`_id\` field; object: ${JSON.stringify(responseObject)}`)
        }
        switch (responseObject.__typename) {
          // Add custom ids here; or with a `typePolicy`
          default:
            return defaultDataIdFromObject(responseObject)
        }
      },
      typePolicies: {
        platform: {
          keyFields: ['name'],
        },
        platforms: {
          keyFields: ['name'],
        },
        delisted_nfts: {
          keyFields: ['nft_id'],
        },
      },
    }),
    connectToDevTools: true,
  })
}

const computeApolloLink = (
  getAccessTokenSilently: () => Promise<string>,
  shouldGrantAdminPermissions: boolean,
): ApolloLink => {
  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently()
    return {
      headers: {
        'x-hasura-role': shouldGrantAdminPermissions ? 'admin' : 'user',
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const httpLink = createHttpLink({
    uri: 'https://' + process.env.NEXT_PUBLIC_HASURA_API_URI,
  })

  if (typeof window === 'undefined') {
    // server-side
    return authLink.concat(httpLink)
  } else {
    // client-side
    const wsLink = new GraphQLWsLink(
      createClient({
        url: 'wss://' + process.env.NEXT_PUBLIC_HASURA_API_URI,
        connectionParams: async () => {
          const token = await getAccessTokenSilently()
          return {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        },
      }),
    )

    const splitLink = wsLink
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            )
          },
          wsLink,
          httpLink,
        )
      : null

    return authLink.concat(splitLink)
  }
}

export default AuthorizedApolloProvider
