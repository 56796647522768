import { GCS_PREFIX } from './web2'

export const DOPPEL_VISION_ASSETS_BUCKETS = 'doppel_vision_assets'

export const extractGCSDetails = (
  url: string,
): { bucketName: string; objectName: string } | null => {
  const match = url.match(/https:\/\/storage\.googleapis\.com\/([^\\/]+)\/(.+)/)

  if (!match || match.length < 3) {
    return null
  }

  const bucketName = match[1]
  const objectName = match[2]

  return { bucketName, objectName }
}

export const getBucketNameAndObjectName = (
  url: string,
): { bucketName: string; objectName: string } => {
  const urlWithoutPrefix = url.substring(GCS_PREFIX.length)
  const firstSlashIndex: number = urlWithoutPrefix.indexOf('/')
  const bucketName = urlWithoutPrefix.substring(0, firstSlashIndex)
  const objectName = urlWithoutPrefix.substring(firstSlashIndex + 1)

  return { bucketName, objectName }
}
